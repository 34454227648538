@charset "UTF-8";
.theme-dark {
  --app-background: rgb(39, 38, 38);
  --dock-background: rgba(51, 51, 51, 1);
  --default-font-color: rgba(255, 255, 255, 1);
  --grey-font-color: rgba(118, 118, 118);
  --table-odd-background: rgba(60, 60, 60, 1);
  --table-even-background: rgba(79, 79, 79, 1);
  --table-odd-lighter-bg: rgb(66, 66, 66);
  --table-even-lighter-bg: rgb(83, 83, 83);
  --disabled-color: rgb(118, 118, 118, 1);
  --hover-color: var(--primary-color);
  --active-color: var(--primary-color);
  --list-border: 0.75px solid rgb(79, 79, 79, 1);
  --expiry-table-bg: rgba(0, 0, 0, 1);
  --marketsheet-headers: var(--primary-color);
  --dock-border: none;
  --sidebar-bg: var(--secondary-color);
  --sidebar-font-color: var(--secondary-font-color);
  --border-color: rgb(79, 79, 79, 1);
  --contrast-border-color: rgba(79, 79, 79, 1);
  --primary-font-color: var(--default-font-color);
  --hover-modifier: var(--hover-modifier);
}

.theme-light {
  --app-background: rgba(88, 95, 109, 0.3);
  --dock-background:rgba(255, 255, 255, 1);
  --default-font-color: rgba(53, 60, 73, 1);
  --grey-font-color: rgba(88, 95, 109, 1);
  --table-odd-background: rgba(255, 255, 255, 1);
  --table-even-background: rgba(232, 233, 237, 1);
  --table-odd-lighter-bg: rgba(255, 255, 255, 1);
  --table-even-lighter-bg: rgb(237, 238, 241);
  --disabled-color: rgb(156, 162, 172);
  --hover-color: var(--primary-color);
  --active-color: var(--primary-color);
  --list-border: 0.75px solid rgba(191, 195, 204, 1);
  --expiry-table-bg: rgba(232, 233, 237, 1);
  --marketsheet-headers: var(--primary-color);
  --dock-border: 0.75px solid rgba(232, 233, 237, 1);
  --sidebar-bg: var(--secondary-color);
  --sidebar-font-color: var(--secondary-font-color);
  --border-color: rgba(191, 195, 204, 1);
  --contrast-border-color: rgb(146, 146, 146);
  --primary-font-color: var(--default-font-color);
  --hover-modifier: brightness(90%);
}

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.pickr {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.pickr * {
  box-sizing: border-box;
}

.pickr .pcr-button {
  position: relative;
  height: 2em;
  width: 2em;
  padding: 0.5em;
  border-radius: 0.15em;
  cursor: pointer;
  background: transparent;
  transition: background-color 0.3s;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

.pickr .pcr-button:before {
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 0.5em;
  border-radius: 0.15em;
  z-index: -1;
}

.pickr .pcr-button:after, .pickr .pcr-button:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pickr .pcr-button:after {
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="%2342445A" stroke-width="5px" stroke-linecap="round"><path d="M45,45L5,5"></path><path d="M45,5L5,45"></path></svg>') no-repeat 50%;
  background-size: 70%;
  opacity: 0;
}

.pickr .pcr-button.clear:after {
  opacity: 1;
}

.pickr .pcr-button.disabled {
  cursor: not-allowed;
}

.pcr-app {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  box-shadow: 0 0.2em 1.5em 0 rgba(0, 0, 0, 0.1), 0 0 1em 0 rgba(0, 0, 0, 0.02);
  top: 5px;
  height: 15em;
  width: 28em;
  max-width: 95vw;
  padding: 0.8em;
  border-radius: 0.1em;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.pcr-app.visible {
  visibility: visible;
  opacity: 1;
}

.pcr-app .swatches {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1.6em, 1fr));
  margin-top: 0.75em;
}

.pcr-app .swatches > div {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.15em;
  cursor: pointer;
  margin: 0 2px 2px 0;
  flex-shrink: 0;
  justify-self: center;
  transition: all 0.3s;
  overflow: hidden;
}

.pcr-app .swatches > div:before {
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 6px;
  border-radius: 0.15em;
  z-index: -1;
}

.pcr-app .swatches > div:after, .pcr-app .swatches > div:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pcr-app .swatches > div:after {
  background: currentColor;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.15em;
  box-sizing: border-box;
}

.pcr-app .swatches > div:hover {
  filter: brightness(1.1);
}

.pcr-app .pcr-interaction {
  display: flex;
  align-items: center;
  margin: 1em -0.2em 0;
}

.pcr-app .pcr-interaction > * {
  margin: 0 0.2em;
}

.pcr-app .pcr-interaction input {
  padding: 0.5em 0.6em;
  border: none;
  outline: none;
  letter-spacing: 0.07em;
  font-size: 0.75em;
  text-align: center;
  cursor: pointer;
  color: #c4c4c4;
  background: #f8f8f8;
  border-radius: 0.15em;
  transition: all 0.15s;
}

.pcr-app .pcr-interaction input:hover {
  color: grey;
}

.pcr-app .pcr-interaction .pcr-result {
  color: grey;
  text-align: left;
  flex-grow: 1;
  min-width: 1em;
  transition: all 0.2s;
  border-radius: 0.15em;
  background: #f8f8f8;
  cursor: text;
  padding-left: 0.8em;
}

.pcr-app .pcr-interaction .pcr-result:focus {
  color: #4285f4;
}

.pcr-app .pcr-interaction .pcr-result::selection {
  background: #4285f4;
  color: #fff;
}

.pcr-app .pcr-interaction .pcr-type.active {
  color: #fff;
  background: #4285f4;
}

.pcr-app .pcr-interaction .pcr-clear, .pcr-app .pcr-interaction .pcr-save {
  color: #fff;
  width: auto;
}

.pcr-app .pcr-interaction .pcr-save {
  background: #4285f4;
}

.pcr-app .pcr-interaction .pcr-save:hover {
  background: #4370f4;
  color: #fff;
}

.pcr-app .pcr-interaction .pcr-clear {
  background: #f44250;
}

.pcr-app .pcr-interaction .pcr-clear:hover {
  background: #db3d49;
  color: #fff;
}

.pcr-app .pcr-selection {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.pcr-app .pcr-selection .pcr-picker {
  position: absolute;
  height: 18px;
  width: 18px;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  cursor: -moz-grab;
  cursor: -webkit-grabbing;
}

.pcr-app .pcr-selection .pcr-color-preview {
  position: relative;
  z-index: 1;
  width: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0.75em;
}

.pcr-app .pcr-selection .pcr-color-preview:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 0.5em;
  border-radius: 0.15em;
  z-index: -1;
}

.pcr-app .pcr-selection .pcr-color-preview .pcr-last-color {
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0.15em 0.15em 0 0;
}

.pcr-app .pcr-selection .pcr-color-preview .pcr-current-color {
  border-radius: 0 0 0.15em 0.15em;
}

.pcr-app .pcr-selection .pcr-color-preview .pcr-current-color, .pcr-app .pcr-selection .pcr-color-preview .pcr-last-color {
  background: transparent;
  width: 100%;
  height: 50%;
}

.pcr-app .pcr-selection .pcr-color-chooser, .pcr-app .pcr-selection .pcr-color-opacity, .pcr-app .pcr-selection .pcr-color-palette {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.pcr-app .pcr-selection .pcr-color-palette {
  width: 100%;
  z-index: 1;
}

.pcr-app .pcr-selection .pcr-color-palette .pcr-palette {
  height: 100%;
  border-radius: 0.15em;
}

.pcr-app .pcr-selection .pcr-color-palette .pcr-palette:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 0.5em;
  border-radius: 0.15em;
  z-index: -1;
}

.pcr-app .pcr-selection .pcr-color-chooser, .pcr-app .pcr-selection .pcr-color-opacity {
  margin-left: 0.75em;
}

.pcr-app .pcr-selection .pcr-color-chooser .pcr-picker, .pcr-app .pcr-selection .pcr-color-opacity .pcr-picker {
  left: 50%;
  transform: translateX(-50%);
}

.pcr-app .pcr-selection .pcr-color-chooser .pcr-slider, .pcr-app .pcr-selection .pcr-color-opacity .pcr-slider {
  width: 8px;
  height: 100%;
  border-radius: 50em;
}

.pcr-app .pcr-selection .pcr-color-chooser .pcr-slider {
  background: linear-gradient(180deg, red, #ff0, #0f0, #0ff, #00f, #f0f, red);
}

.pcr-app .pcr-selection .pcr-color-opacity .pcr-slider {
  background: linear-gradient(180deg, transparent, #000), url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 100%, 50%;
}

@font-face {
  font-family: "DINOT";
  src: url("../assets/fonts/DINOT.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOT-Bold";
  src: url("../assets/fonts/DINOT-Bold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOT-BoldItalic";
  src: url("../assets/fonts/DINOT-BoldItalic.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOT-Italic";
  src: url("../assets/fonts/DINOT-Italic.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOT-Medium";
  src: url("../assets/fonts/DINOT-Medium.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOT-MediumItalic";
  src: url("../assets/fonts/DINOT-MediumItalic.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Icons";
  src: url("../assets/fonts/open-iconic.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/Roboto-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("../assets/fonts/Roboto-Italic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../assets/fonts/Roboto-BoldItalic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Black";
  src: url("../assets/fonts/Roboto-Black.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("../assets/fonts/Roboto-BlackItalic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("../assets/fonts/Roboto-Italic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/Roboto-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("../assets/fonts/Roboto-MediumItalic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("../assets/fonts/Roboto-Thin.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("../assets/fonts/Roboto-ThinItalic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Light";
  src: url("../assets/fonts/Roboto-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: url("../assets/fonts/Roboto-LightItalic.ttf");
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4 {
  margin-bottom: 0;
}

h1 {
  font-size: 28.5px;
  font-weight: normal;
}

h2 {
  font-size: 19.5px;
  font-weight: normal;
}

h3 {
  font-size: 15px;
  font-weight: bold;
}

h4 {
  font-size: 13.5px;
  font-weight: bold;
}

body {
  font-size: 13.5px;
}

p,
span,
label,
div {
  font-size: 100%;
}

.oi {
  font-size: 15px;
}

.small-text {
  font-size: 12px;
}

input {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  caret-color: var(--primary-color);
}

input[type=text],
input[type=password],
input[type=number] {
  border: none;
  border-radius: 0;
  border-bottom: 0.75px solid var(--primary-color);
  color: rgb(53, 60, 73);
  font-size: 13.5px;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=number]:focus {
  outline: none;
  box-shadow: none;
}

/*
* Range input
*/
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 2.625px 0;
  background-color: transparent;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2.25px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgb(0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 0px;
  border: 0px solid rgba(13, 13, 13, 0);
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px rgb(0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(13, 13, 13, 0);
  height: 7.5px;
  width: 7.5px;
  border-radius: 37.5px;
  background: var(--primary-color);
  color: var(--secondary-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2.625px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--primary-color);
  color: var(--secondary-color);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 2.25px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(13, 13, 13, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 0px;
  border: 0px solid rgba(13, 13, 13, 0);
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(13, 13, 13, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(13, 13, 13, 0);
  height: 7.5px;
  width: 7.5px;
  border-radius: 37.5px;
  background: var(--primary-color);
  color: var(--secondary-color);
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 2.25px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: rgb(53, 60, 73);
  border: 0px solid rgba(13, 13, 13, 0);
  border-radius: 0px;
  box-shadow: 0px 0px 0px rgba(13, 13, 13, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type=range]::-ms-fill-upper {
  background: var(--primary-color);
  color: var(--secondary-color);
  border: 0px solid rgba(13, 13, 13, 0);
  border-radius: 0px;
  box-shadow: 0px 0px 0px rgba(13, 13, 13, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(13, 13, 13, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(13, 13, 13, 0);
  height: 7.5px;
  width: 7.5px;
  border-radius: 37.5px;
  background: var(--primary-color);
  color: var(--secondary-color);
  cursor: pointer;
  height: 2.25px;
}

input[type=range]:focus::-ms-fill-lower {
  background: var(--primary-color);
  color: var(--secondary-color);
}

input[type=range]:focus::-ms-fill-upper {
  background: var(--primary-color);
  color: var(--secondary-color);
}

/*
* Checkbox & radio inputs
**/
input[type=radio]:not(:checked),
input[type=radio]:checked,
input[type=checkbox]:not(:checked),
input[type=checkbox]:checked {
  position: absolute;
  left: -9999px;
}

input[type=radio]:not(:checked).horizontal,
input[type=radio]:checked.horizontal,
input[type=checkbox]:not(:checked).horizontal,
input[type=checkbox]:checked.horizontal {
  position: absolute;
  top: -9999px;
  left: auto;
}

input[type=radio]:not(:checked) + label,
input[type=radio]:checked + label {
  position: relative;
  overflow: hidden;
  padding-left: 18.75px;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  line-height: 15px;
}

input[type=checkbox]:not(:checked) + label,
input[type=checkbox]:checked + label {
  position: relative;
  overflow: hidden;
  padding-left: 18.75px;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  font-size: 13.5px;
  line-height: 16.5px;
}

input[type=radio] + label:after,
input[type=checkbox] + label:before,
input[type=checkbox] + label:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
}

input[type=checkbox]:not(:checked) + label:before {
  top: 0px;
  width: 13.5px;
  height: 13.5px;
}

input[type=checkbox]:not(:checked) + label:after {
  top: 1.5px;
  width: 13.5px;
  height: 13.5px;
  border: 1.5px solid rgb(88, 95, 109);
  z-index: 0;
  border-radius: 1.75px;
}

input[type=checkbox]:checked + label:before {
  top: 1.5px;
  width: 6px;
  height: 12px;
  border-top: 0.75px solid transparent;
  border-left: 0.75px solid transparent;
  border-right: 1.5px solid var(--secondary-color);
  border-bottom: 1.5px solid var(--secondary-color);
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

input[type=checkbox]:checked + label:after {
  top: 1.5px;
  width: 13.5px;
  height: 13.5px;
  border: 1.5px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 1.75px;
  z-index: 0;
}

input[type=radio]:not(:checked) + label:after {
  top: 0px;
  width: 13.5px;
  height: 13.5px;
  border: 1.5px solid rgb(88, 95, 109);
  z-index: 0;
  border-radius: 50%;
}

input[type=radio]:checked + label:after {
  top: 0.75px;
  left: 1.5px;
  width: 12.75px;
  height: 12.75px;
  border: 1.5px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 0.75px var(--primary-color);
  background-color: var(--primary-color);
  color: var(--secondary-color);
  z-index: 0;
  border-radius: 50%;
}

/* disabled radio */
input[type=radio]:disabled:not(:checked) + label:after {
  border: 1.5px solid rgb(191, 195, 204);
}

input[type=radio]:disabled:checked + label:after {
  background-color: rgb(191, 195, 204);
}

/* disabled checkbox */
input[type=checkbox]:disabled:not(:checked) + label:before,
input[type=checkbox]:disabled:checked + label:before {
  top: 1.5px;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  width: 13.5px;
  height: 13.5px;
  border: 1.5px solid rgb(191, 195, 204);
}

input[type=radio]:disabled + label,
input[type=checkbox]:disabled + label {
  color: rgb(191, 195, 204);
}

input[type=radio]:disabled:not(:checked) + label:hover:after,
input[type=checkbox]:disabled:not(:checked) + label:hover:before {
  cursor: not-allowed;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  font-family: "DINOT";
  color: rgb(53, 60, 73);
  font-size: 13.5px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin: 0;
  cursor: pointer;
}

.number-spinner {
  white-space: nowrap;
}
.number-spinner .spinner-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: inline-block;
}
.number-spinner .spinner-vertical a {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
  text-align: center;
  border: 0px;
  background-color: transparent;
  color: var(--primary-color);
  outline: none;
}
.number-spinner .spinner-vertical a:last-child {
  margin-top: -2px;
}
.number-spinner .spinner-vertical span {
  position: absolute;
  top: 0;
  left: 4px;
  text-align: center;
}
.number-spinner .spinner-vertical span.oi {
  color: var(--primary-color);
}
.number-spinner.input-group {
  display: inline-block;
  width: initial;
}
.number-spinner.input-group .form-control {
  flex: initial;
  width: auto;
}
.number-spinner input {
  display: inline-block;
}

input[type=text],
input[type=password],
input[type=number] {
  background: transparent;
  color: var(--default-font-color);
}

.login-form input[type=text], .login-form input[type=password] {
  color: rgb(53, 60, 73);
}

.btn {
  text-align: center;
  padding: 7.5px;
  font-size: 0.75rem;
  border-radius: 0%;
  font-weight: 400;
}
.btn.w50 {
  width: 37.5px;
}
.btn.w100 {
  width: 75px;
}
.btn.w150 {
  width: 112.5px;
}
.btn.w200 {
  width: 150px;
}
.btn.w250 {
  width: 187.5px;
}
.btn.btn-primary {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  border: none;
}
.btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active, .btn.btn-primary:hover, .btn.btn-primary:active {
  background-color: var(--primary-color);
  filter: var(--hover-modifier);
}
.btn.btn-primary:focus {
  background-color: var(--primary-color);
  box-shadow: 0 0 0 0.75px var(--secondary-color);
  filter: var(--hover-modifier);
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem var(--dock-background);
}
.btn.btn-secondary {
  background-color: transparent;
  color: var(--primary-color);
  border: 0.75px solid var(--primary-color);
}
.btn.btn-secondary:hover, .btn.btn-secondary:active {
  background-color: var(--secondary-color);
  filter: var(--hover-modifier);
}
.btn.btn-secondary:focus {
  background-color: var(--secondary-color);
  box-shadow: 0 0 0 0.75px var(--primary-color);
  filter: var(--hover-modifier);
}
.btn.btn-approval {
  background-color: var(--primary-color);
  color: var(--default-font-color);
  border: 0.75px solid var(--primary-color);
}
.btn.btn-approval:not(:disabled):not(.disabled):active, .btn.btn-approval:not(:disabled):not(.disabled).active, .btn.btn-approval:hover, .btn.btn-approval:active {
  filter: var(--hover-modifier);
  background-color: var(--primary-color);
  color: var(--approval-font-color);
}
.btn.btn-approval:focus {
  filter: var(--hover-modifier);
}
.btn.btn-cancellation {
  color: var(--default-font-color);
  border: 0.75px solid var(--default-font-color);
  margin-right: 5px;
  background-color: transparent;
}
.btn.btn-cancellation:hover, .btn.btn-cancellation:active {
  filter: brightness(80%);
}
.btn.btn-cancellation:focus {
  filter: brightness(80%);
}
.btn.btn-delete {
  color: rgb(248, 86, 86);
}
.btn.btn-delete:hover, .btn.btn-delete:active {
  color: #fa8787;
}
.btn.btn-delete:focus {
  color: rgb(248, 86, 86);
  filter: var(--hover-modifier);
}
.btn.disabled {
  color: var(--disabled-color) !important;
  cursor: not-allowed;
}

.btn-group-toggle label.btn {
  padding: 3.75px 7.5px;
  background-color: rgb(191, 195, 204);
  color: rgb(255, 255, 255);
}
.btn-group-toggle label.btn.ask.active {
  background-color: rgb(241, 146, 122);
}
.btn-group-toggle label.btn.bid.active {
  background-color: rgb(170, 249, 132);
}

.form-control {
  font-size: 0.75rem;
}
.form-control:focus {
  border-color: var(--primary-color);
}

.popover {
  padding: 7.5px !important;
  border-radius: 4px;
  border-color: rgb(191, 195, 204);
  width: auto;
  overflow-x: auto;
  font-family: var(--client-font-name);
}
.popover .popover-title {
  font-size: 13.5px !important;
  font-weight: bold !important;
  color: rgb(53, 60, 73);
}
.popover .arrow {
  display: none;
}
.popover__market-filter {
  max-width: 637.5px;
}

.oi {
  color: var(--default-font-color);
  font-size: 15px;
}
.oi:hover, .oi:active {
  color: var(--default-font-color);
  filter: var(--hover-modifier);
}
.oi.white {
  color: rgb(255, 255, 255);
}
.oi.white:hover, .oi.white:active {
  color: rgb(255, 255, 255);
}
.oi.active {
  color: var(--active-color);
}
.oi.active:hover, .oi.active:active {
  color: var(--active-color);
  filter: var(--hover-modifier);
}
.oi.failed {
  color: rgb(248, 86, 86);
}
.oi.failed:hover, .oi.failed:active {
  color: #fa8787;
}
.oi.success {
  color: rgb(0, 173, 151);
}
.oi.success:hover, .oi.success:active {
  color: #00e0c4;
}
.oi.open {
  color: rgb(88, 95, 109);
}
.oi.open:hover, .oi.open:active {
  color: #6f7889;
}
.oi.pending {
  color: rgb(255, 169, 100);
}
.oi.pending:hover, .oi.pending:active {
  color: #ffc597;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  margin: 3.75px;
  -webkit-border-radius: 3.75px;
}

* {
  scrollbar-width: thin;
}

.theme-dark > * {
  scrollbar-color: var(--grey-font-color) var(--table-odd-background);
}

.theme-light * {
  scrollbar-color: rgb(88, 95, 109) transparent;
}

.theme-dark *::-webkit-scrollbar-thumb {
  background-color: rgb(118, 118, 118);
}

.theme-dark *::-webkit-scrollbar-track {
  background-color: rgb(60, 60, 60);
}

.theme-light *::-webkit-scrollbar-thumb {
  background-color: rgb(88, 95, 109);
}

.theme-light *::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.uitable {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  position: relative;
}
.uitable table {
  min-width: 0 !important;
  color: rgb(53, 60, 73);
  font-size: 13.5px;
  margin-bottom: 37.5px !important;
  border-collapse: separate;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  color: var(--default-font-color);
}
.uitable table.row-table {
  width: 100%;
  margin: 0 !important;
  background-color: transparent;
}
.uitable table.row-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.uitable table.row-table tbody tr td:first-child {
  text-align: center;
}
.uitable table.row-table tbody tr:nth-of-type(odd) {
  background-color: var(--table-odd-background);
}
.uitable table.row-table tbody tr:nth-of-type(even) {
  background-color: var(--table-even-background);
}
.uitable table thead th {
  border-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
  font-weight: bold;
  border-top: none;
  overflow: hidden;
}
.uitable table thead th:first-child {
  padding-left: 7.5px;
}
.uitable table thead th:last-child {
  padding-right: 7.5px;
}
.uitable table thead th .oi-arrow-thick-bottom,
.uitable table thead th .oi-arrow-thick-top {
  color: var(--default-font-color);
}
.uitable table tbody.pinnedTable.scrollable, .uitable table tbody.unpinnedTable.scrollable {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.uitable table tbody.pinnedTable.scrollable {
  max-height: 50%;
  overflow-y: scroll;
  filter: brightness(80%);
  border-bottom: 2px solid var(--primary-color);
  border-top: none;
}
.uitable table tbody.unpinnedTable.scrollable {
  flex: 1;
  overflow-y: auto;
}
.uitable table tbody {
  border-top: none;
  transform: translateZ(0);
}
.uitable table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
  cursor: pointer;
  overflow-x: hidden;
}
.uitable table tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2.25px 0;
  border-top: 0;
  overflow: hidden;
}
.uitable table tbody tr td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 7.5px;
}
.uitable table tbody tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-right: 7.5px;
}
.uitable.hidden-headlines table thead,
.uitable.hidden-headlines .empty-row {
  display: none;
}
.uitable .search {
  position: fixed;
  top: 30px;
  left: 7.5px;
  width: calc(100% - 15px);
  background: var(--table-odd-background);
}
.uitable .table-visibility-filter {
  position: fixed;
  top: 30px;
  right: 15px;
  width: calc(100% - 30px);
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  border-radius: 4px;
  z-index: 5;
  overflow: auto;
  max-width: 300px;
}
.uitable .table-visibility-filter .form-check {
  padding-left: 0;
}
.uitable .table-visibility-filter.hidden {
  display: none;
}
.uitable .table-visibility-filter .row {
  margin: 0;
}
.uitable__actions {
  position: fixed;
  bottom: 2px;
  left: 2px;
  width: calc(100% - 4px);
  padding: 7.5px;
  background-color: var(--dock-background);
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: space-between;
}
.uitable__actions > div {
  margin: 7.5px 0;
}
.uitable__actions .form-check {
  margin-left: 7.5px;
}
.uitable__actions .form-check .form-check-input {
  margin-top: 7.5px;
}
.uitable__actions .form-check .form-check-label {
  margin-top: 7.5px;
  min-width: 5.25rem;
}
.uitable__actions .selection-actions {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: flex-start;
}

.orderbook .books-view {
  display: table;
}
.orderbook .expiry,
.orderbook .instrument {
  display: table-cell;
}
.orderbook .expiry tbody.empty-row tr td,
.orderbook .instrument tbody.empty-row tr td {
  height: 6px;
  font-size: 0px;
}
.orderbook .expiry-header,
.orderbook .instrument-header {
  display: flex !important;
  position: sticky;
  top: 0px;
  padding: 0 0;
  z-index: 11;
  height: 30px;
  text-transform: uppercase;
  background: var(--dock-background);
  color: var(--default-font-color);
}
.orderbook .instrument {
  padding: 0px 6px 0px 6px;
}
.orderbook .instrument-header h3 {
  padding-left: 7.5px;
  white-space: nowrap;
  display: block;
  position: relative;
  bottom: 5px;
  height: 16px;
  margin-top: unset;
  position: absolute;
}
.orderbook .instrument.block-odd table tbody {
  background-color: var(--table-odd-background);
}
.orderbook .instrument.block-odd table tbody.empty-row {
  background-color: transparent;
}
.orderbook .instrument.block-odd table tbody tr:nth-of-type(odd) td.compact {
  filter: brightness(120%);
}
.orderbook .instrument.block-even table tbody {
  background-color: var(--table-even-background);
}
.orderbook .instrument.block-even table tbody.empty-row {
  background-color: transparent;
}
.orderbook .instrument.block-even table tbody tr:nth-of-type(odd) td.compact {
  filter: brightness(120%);
}
.orderbook .instrument table {
  table-layout: fixed;
  color: var(--default-font-color);
}
.orderbook .instrument table thead tr:nth-child(1) th {
  background-color: var(--marketsheet-headers);
  position: sticky;
  top: 30px;
  z-index: 10;
  border: 0px;
}
.orderbook .instrument table thead tr:nth-of-type(odd) {
  background-color: inherit;
}
.orderbook .instrument table thead tr:nth-of-type(even) {
  background-color: inherit;
}
.orderbook .instrument table thead tr.table-active {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}
.orderbook .instrument table thead tr:hover {
  background-color: var(--expiry-table-bg);
}
.orderbook .instrument table thead tr td.order.price .cell-symbol {
  color: var(--grey-font-color);
}
.orderbook .instrument table thead tr.expanded-row:hover tbody {
  background-color: var(--expiry-table-bg);
}
.orderbook .instrument table thead tr td.trade label.entry.red.bottom {
  color: rgb(229, 77, 77);
}
.orderbook .instrument table thead tr td.trade label.entry.green.top {
  color: rgb(131, 208, 104);
}
.orderbook .instrument table thead tr th {
  text-align: center;
  vertical-align: middle;
  min-width: 60px;
}
.orderbook .instrument table thead tr th.column label.entry {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 110%;
  width: 90%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderbook .instrument table thead tr th.column.bp label.entry {
  background-color: var(--bid-overlay-background);
}
.orderbook .instrument table thead tr th.column.ap label.entry {
  background-color: var(--ask-overlay-background);
}
.orderbook .instrument table tbody tr td {
  text-align: center;
}
.orderbook .instrument table tbody tr td.order.price.bid label.entry {
  background-color: var(--bid-overlay-background);
}
.orderbook .instrument table tbody tr td.order.price.ask label.entry {
  background-color: var(--ask-overlay-background);
}
.orderbook .instrument table tbody tr td.order.price label.entry {
  font-size: 120%;
}
.orderbook .instrument table tbody tr td.ask, .orderbook .instrument table tbody tr td.bid {
  cursor: pointer;
}
.orderbook .instrument table tbody tr td.ask label, .orderbook .instrument table tbody tr td.bid label {
  cursor: pointer;
}
.orderbook .instrument table tbody tr td.ask .cell-symbol, .orderbook .instrument table tbody tr td.bid .cell-symbol {
  font-size: 90%;
  margin-left: 2px;
  color: var(--disabled-color);
}
.orderbook .instrument table tbody tr td.trade .oi {
  font-size: 10px;
}
.orderbook .instrument table tbody tr td.inside-info {
  background-color: var(--inside-informations-background) !important;
}
.orderbook .instrument table tbody tr td.not-interactive {
  background-color: var(--not-interactive-background) !important;
}
.orderbook .instrument table tbody tr td.not-interactive table.inner td {
  background-color: var(--not-interactive-background) !important;
}
.orderbook .instrument table tbody tr td label.td-entry {
  width: 90%;
}
.orderbook .instrument table tbody tr td .entry {
  width: 90%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
}
.orderbook .instrument table tbody tr td .entry.entry-update {
  background-color: var(--order-highlight-background) !important;
}
.orderbook .instrument table tbody tr td .entry.counterparty {
  justify-content: flex-start;
  padding-right: 0;
  padding-left: 5%;
}
.orderbook .instrument table tbody tr td.aon-order .entry {
  padding-right: 7.5px;
}
.orderbook .instrument table tbody tr td label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.orderbook .instrument table tbody tr td .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.orderbook .instrument table tbody tr td .layer.suspended {
  background-color: var(--order-suspended-background);
}
.orderbook .instrument table tbody tr td .layer.readonly {
  background-color: var(--order-readonly-background);
}
.orderbook .instrument table tbody tr td .layer.own-order {
  background-color: var(--order-own-background);
}
.orderbook .instrument table tbody tr td .layer.non-own-order {
  background-color: var(--order-background);
}
.orderbook .instrument table tbody tr td .layer.quote-request {
  background-color: var(--quote-request-background);
}
.orderbook .instrument table tbody tr td .layer.preview-order {
  background-color: var(--order-preview-background);
}
.orderbook .instrument table tbody tr td .layer.implied {
  background-color: var(--implied-background);
}
.orderbook .instrument table tbody tr td .layer.periodType-quarter_hour {
  background-color: var(--quarter_hour-background);
}
.orderbook .instrument table tbody tr td .layer.periodType-half_hour {
  background-color: var(--half_hour-background);
}
.orderbook .instrument table tbody tr td .layer.periodType-hour {
  background-color: var(--hour-background);
}
.orderbook .instrument table tbody tr td .layer.periodType-block_2_h {
  background-color: var(--block_2_h-background);
}
.orderbook .instrument table tbody tr td .layer.periodType-block_4_h {
  background-color: var(--block_4_h-background);
}
.orderbook .instrument table tbody tr td .layer.routed {
  background-color: var(--routed-background);
}
.orderbook .instrument table tbody tr td.not-interactive {
  cursor: default;
}
.orderbook .instrument table tbody tr td.not-interactive label.entry {
  cursor: default;
}
.orderbook .instrument table tbody tr td.compact {
  font-size: 100%;
}
.orderbook .instrument table tbody tr td.compact .entry {
  flex-direction: column;
  justify-content: center;
}
.orderbook .instrument table tbody tr td.compact.order.price label.entry {
  font-size: 100%;
}
.orderbook .instrument table tbody tr td.compact.order.price label.entry span {
  line-height: 100%;
}
.orderbook .instrument table.inner {
  width: 100%;
}
.orderbook .execprice-arrow {
  margin-right: 2px;
}
.orderbook .execprice-arrow.red {
  color: red;
}
.orderbook .execprice-arrow.gray {
  color: gray;
}
.orderbook .execprice-arrow.green {
  color: green;
}
.orderbook .expiry {
  position: sticky;
  left: -7.5px;
  z-index: 15;
  border-color: white;
  margin-right: 6px;
}
.orderbook .expiry thead tr:nth-child(1) th {
  position: sticky;
  top: 30px;
  z-index: 10;
  padding-right: 0px;
  background-color: var(--marketsheet-headers);
}
.orderbook .expiry table thead tr th {
  border-top: 0px;
  vertical-align: middle;
  padding-left: 15px;
}
.orderbook .expiry table thead tr th label {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0px;
  display: flex;
  align-items: center;
  height: 100%;
}
.orderbook .expiry table tbody {
  background-color: var(--expiry-table-bg);
}
.orderbook .expiry table tbody tr td {
  cursor: pointer;
  padding-right: 20px;
  border: none;
}
.orderbook .expiry table tbody tr td.expiryType {
  color: var(--grey-font-color);
  padding-left: 15px;
  display: none;
}
.orderbook .expiry table tbody tr td.expiryType span {
  font-size: 10px;
}
.orderbook .expiry table tbody tr td label {
  margin: 0;
  display: table-header-group;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 2px;
  font-size: 120%;
}
.orderbook .expiry table tbody tr td.expiry-HOUR {
  font-weight: bold;
}
.orderbook .expiry table tbody tr td .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.orderbook .expiry table tbody tr td .layer.expiry-HOUR {
  background-color: var(--hour-background);
}
.orderbook .expiry table tbody tr td .layer.expiry-QUARTER_HOUR {
  background-color: var(--quarter_hour-background);
}
.orderbook .expiry table tbody tr td .layer.expiry-HALF_HOUR {
  background-color: var(--half_hour-background);
}
.orderbook .expiry table tbody tr td .layer.expiry-BLOCK_2_H {
  background-color: var(--block_2_h-background);
}
.orderbook .expiry table tbody tr td .layer.expiry-BLOCK_4_H {
  background-color: var(--block_4_h-background);
}
.orderbook .expiry table tbody tr td .inner tr {
  background-color: transparent !important;
}
.orderbook .expiry table tbody tr td .inner tr td {
  background-color: transparent !important;
}
.orderbook .expiry table tbody tr td.compact label {
  align-items: start;
}
.orderbook .expiry .carret {
  cursor: pointer;
  position: absolute;
  top: 3.75px;
  right: 3.75px;
  height: 7.5px;
  width: 7.5px;
}
.orderbook .expiry .carret .bar {
  position: absolute;
  width: 7.5px;
  height: 2.25px;
  background: rgb(53, 60, 73);
  top: 7.5px;
  color: var(--default-font-color);
}
.orderbook .expiry .carret .bar:nth-child(1) {
  transform: translateY(-150%);
}
.orderbook .expiry .carret .bar:nth-child(2) {
  transform: translateY(150%);
}
.orderbook .expiry .carret.up .bar:nth-child(1) {
  transform-origin: left center;
  transform: translateX(55%) translateY(-100%) rotate(45deg);
}
.orderbook .expiry .carret.up .bar:nth-child(2) {
  transform-origin: left center;
  transform: translateX(190%) translateY(-100%) rotate(135deg);
}
.orderbook .expiry .carret.down {
  margin-top: 0px;
}
.orderbook .expiry .carret.down .bar:nth-child(1) {
  transform-origin: left center;
  transform: translateX(70%) translateY(-200%) rotate(45deg);
}
.orderbook .expiry .carret.down .bar:nth-child(2) {
  transform-origin: left center;
  transform: translateX(140%) translateY(0%) rotate(135deg);
}
.orderbook table {
  color: rgb(53, 60, 73);
  width: 100%;
}
.orderbook table.inner tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}
.orderbook table.inner tbody tr td {
  padding: 0;
}
.orderbook table thead th {
  border-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  padding: 0;
  height: 26.25px;
  min-height: 26.25px;
  color: var(--default-font-color);
}
.orderbook table tbody tr td {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 0;
  padding: 0;
  height: 26.25px;
  min-height: 26.25px;
  color: var(--default-font-color);
}
.orderbook table tbody tr td.compact {
  height: 45px;
}
.orderbook table tbody.empty-row tr td {
  border-right-color: transparent;
  border-left-color: transparent;
  background-color: var(--dock-background);
}
.orderbook table tbody + tbody {
  border: 0;
}
.orderbook .market-visibility-filter {
  position: fixed;
  top: 30px;
  right: 15px;
  border: 0.75px solid rgb(191, 195, 204);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  z-index: 5;
  overflow: auto;
}

.instrument-w-200 .instrument {
  min-width: 200px;
  max-width: 200px;
}

.instrument-w-200.chartbook .m_chart {
  width: 200px;
}

.instrument-w-250 .instrument {
  min-width: 250px;
  max-width: 250px;
}

.instrument-w-250.chartbook .m_chart {
  width: 250px;
}

.instrument-w-300 .instrument {
  min-width: 300px;
  max-width: 300px;
}

.instrument-w-300.chartbook .m_chart {
  width: 300px;
}

.instrument-w-350.instrument {
  min-width: 350px;
  max-width: 350px;
}

.instrument-w-350.chartbook .m_chart {
  width: 350px;
}

.instrument-w-400 .instrument {
  min-width: 400px;
  max-width: 400px;
}

.instrument-w-400.chartbook .m_chart {
  width: 400px;
}

.instrument-w-500.chartbook .m_chart {
  width: 500px;
}

.instrument-w-600.chartbook .m_chart {
  width: 600px;
}

.instrument-w-700.chartbook .m_chart {
  width: 700px;
}

.instrument-w-800.chartbook .m_chart {
  width: 800px;
}

.instrument-w-default .chartbook .m_chart {
  width: inherit;
}

.favorite__form {
  position: fixed;
  top: 30px;
  right: 0px;
  width: auto;
  max-width: unset;
  overflow-y: auto;
  overflow-x: auto;
  border: var(--list-border);
  padding: 5px;
  background-color: var(--dock-background);
  color: var(--default-font-color);
}

.market__actions {
  position: fixed;
  top: 8px;
  right: 0px;
  margin-right: 15.5px;
  padding-top: 0px;
  z-index: 25;
  background: var(--dock-background);
  width: 100px;
}
.market__actions ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
.market__actions ul li {
  cursor: pointer;
  margin-left: 7.5px;
  float: right;
}
.market__filter .block {
  border-right: 0.75px solid rgb(191, 195, 204);
}
.market__filter .block h3 {
  padding: 7.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.market__filter .block .form-range {
  font-size: 13.5px;
}
.market__filter .block.block-horizontal {
  border-right: 0px;
  height: 100%;
}
.market__filter .block.block-horizontal .horizontal-inputs {
  border-bottom: 0.75px solid rgb(191, 195, 204);
}
.market__filter .block.block-horizontal .horizontal-inputs div {
  padding: 3.75px;
}
.market__filter .block.block-horizontal .horizontal-inputs div.form-group {
  padding: 0;
  margin: 0;
}
.market__filter .table-filter-block {
  max-width: 187.5px;
}
.market__filter .table-filter-block .form-range,
.market__filter .table-filter-block .form-check {
  margin-bottom: 0px;
}
.market__filter .table-filter-block .form-range input[type=checkbox] + label,
.market__filter .table-filter-block .form-check input[type=checkbox] + label {
  height: auto;
  min-height: 15px;
}

.m_chart:not(:nth-last-child(1)) {
  border-right: 1.5px solid rgb(191, 195, 204);
}
.m_chart__header {
  padding: 7.5px;
  color: var(--default-font-color);
}
.m_chart__header h3 {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m_chart__header .chart-options {
  width: 100%;
  min-width: 337.5px;
}
.m_chart__header .chart-options label,
.m_chart__header .chart-options select {
  font-size: 15px;
  font-weight: bold;
}
.m_chart__header .chart-options label {
  text-transform: capitalize;
  margin-bottom: 0;
}
.m_chart__filter .chart-filter-block {
  width: 100%;
  padding-bottom: 7.5px;
  padding-top: 7.5px;
  border-bottom: 0.75px solid rgb(191, 195, 204);
}
.m_chart__filter .chart-filter-block label,
.m_chart__filter .chart-filter-block select {
  font-size: 15px;
  font-weight: bold;
}
.m_chart__filter .chart-filter-block label {
  text-transform: capitalize;
  margin-bottom: 0;
}
.m_chart__filter .chart-filter-block select {
  margin-top: 4px;
}
.m_chart__filter .chart-filter-block:last-child {
  border: 0px;
}

.icon-popover {
  position: fixed;
  top: 30px;
  right: 0px;
  width: auto;
  max-width: unset;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 4px;
  padding: 5px;
  max-height: 100%;
  padding-right: 15px;
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  border: var(--list-border);
}
.icon-popover.left-border {
  left: 0px;
  right: auto;
}

.chart-filter-popover {
  max-width: initial;
}

.expiry-popover {
  position: fixed;
  border: var(--list-border);
}

.expiry-popover {
  padding: 7.5px;
  margin-left: 0.5rem;
  width: 140px;
  border: 1px solid var(--border-color);
}
.expiry-popover .btn-group-toggle {
  border: var(--list-border);
  height: 25px;
  margin-top: 5px;
}
.expiry-popover .btn-group-toggle label {
  background-color: transparent;
  color: rgb(255, 255, 255);
  padding-top: 0.75px;
}
.expiry-popover .btn-group-toggle label.plus {
  width: 25px;
}
.expiry-popover .btn-group-toggle label.minus {
  margin-right: 2px;
  width: 25px;
}
.expiry-popover .btn-group-toggle label.minus.active {
  background-color: var(--dock-background);
}
.expiry-popover .btn-group-toggle label.plus.active {
  background-color: var(--dock-background);
}
.expiry-popover .btn-group-toggle .plus.active:hover, .expiry-popover .btn-group-toggle .minus.active:hover {
  background-color: #a3a8b5;
}
.expiry-popover .d-flex {
  margin-top: 5px;
}
.expiry-popover .arrow {
  left: calc((0.5rem - 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
  position: absolute;
  display: block;
  color: var(--dock-background);
}
.expiry-popover .arrow:after, .expiry-popover .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-right-color: var(--border-color);
}
.expiry-popover .arrow:after {
  left: 1px;
}
.expiry-popover .arrow:before {
  left: 0;
}

#market-context-menu,
.expiry-popover {
  background: var(--table-odd-background);
  border-radius: 4px;
  width: 120stpx;
  z-index: 99;
}
#market-context-menu ul,
.expiry-popover ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#market-context-menu ul li,
.expiry-popover ul li {
  padding: 7.5px;
  cursor: pointer;
}
#market-context-menu ul li:not(:nth-child(1)),
.expiry-popover ul li:not(:nth-child(1)) {
  border-top: var(--list-border);
}
#market-context-menu ul li.disabled,
.expiry-popover ul li.disabled {
  color: rgb(88, 95, 109);
  cursor: default;
  background-color: var(--disabled-color);
}

#market-context-menu {
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  position: fixed;
  min-width: 150px;
  border: var(--list-border);
  font-size: 13.5px;
}
#market-context-menu li:hover {
  background-color: var(--hover-color);
}

.sidebar__recent-actions #market-context-menu {
  position: absolute;
}

.dropdown__menu {
  width: auto;
}
.dropdown__menu ul {
  display: block;
}
.dropdown__menu ul li {
  padding: 3.75px;
  line-height: 13.5px;
  display: block;
  float: none;
  margin-left: 0px;
  min-width: 150px;
  color: var(--default-font-color);
}
.dropdown__menu ul li.disabled {
  cursor: default;
  color: var(--disabled-color);
}
.dropdown__menu ul li:hover {
  background-color: var(--hover-color);
}
.dropdown__menu ul li:not(:nth-child(1)) {
  border-top: var(--list-border);
}

.react-tags {
  position: relative;
  padding: 0;
  border: 0.75px solid rgb(191, 195, 204);
  border-radius: 4px;
  font-size: 13.5px;
  width: 100%;
  cursor: text;
}

.react-tags.is-focused {
  border-color: rgb(191, 195, 204);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 7.5px 7.5px 0;
  border: var(--list-border);
  border-radius: 4px;
  background: var(--primary-color);
  font-size: 12px;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "✕";
  color: var(--default-font-color);
  margin-left: 6px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: rgba(88, 95, 109, 0.3);
}

.react-tags__search {
  display: inline-block;
  padding: 7.5px;
  max-width: 100%;
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

.react-tags__suggestions ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--dock-background);
  border: 0.75px solid rgb(191, 195, 204);
  border-radius: 4px;
}

.react-tags__suggestions li {
  border-bottom: 0.75px solid rgb(191, 195, 204);
  padding: 7.5px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: bold;
  color: var(--default-font-color);
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: rgba(88, 95, 109, 0.3);
}

.react-tags__suggestions li.is-active {
  background: rgba(88, 95, 109, 0.3);
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.popover.fade {
  transition: none;
}

.table-custom-border .orderbook-expiry-row {
  border-bottom: 0.75px solid var(--border-color-background);
}

@keyframes highlight {
  from {
    background-color: var(--order-highlight-background);
  }
  to {
    background-color: transparent;
  }
}
.hover-primary:hover {
  color: var(--primary-color) !important;
}

.diviner {
  display: block;
  border-top: 0.75px solid rgb(191, 195, 204);
}

.hidden-headlines .empty-row:not(:first-child), .hidden-headlines .empty-row:first-child span {
  display: none;
}

.dropdown-list {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.dropdown-list div {
  border: var(--list-border);
  border-radius: 4px;
  padding: 7.5px;
}
.dropdown-list div:hover {
  background-color: rgba(88, 95, 109, 0.3);
}
.dropdown-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 99px;
  font-size: 13.5px;
  position: absolute;
  z-index: 11;
  overflow-y: auto;
  width: 100%;
  color: var(--default-font-color);
  background-color: var(--dock-background);
}
.dropdown-list ul li {
  cursor: pointer;
  padding: 7.5px;
  white-space: nowrap;
}
.dropdown-list ul li:hover {
  background-color: var(--table-even-background);
}
.dropdown-list ul li.selected {
  background-color: rgba(88, 95, 109, 0.3);
}
.dropdown-list ul li.additional-item {
  color: rgb(191, 195, 204);
  font-style: italic;
}
.dropdown-list ul .blocked {
  cursor: not-allowed;
}
.dropdown-list.visible {
  vertical-align: top;
}
.dropdown-list.visible ul {
  position: relative;
}
.dropdown-list input {
  width: 100%;
  background-color: transparent;
}

#quoteRequestForm .form-control, #priceAlarmForm .form-control {
  background-color: transparent;
  color: var(--default-font-color);
  border-bottom: 0.75px solid var(--primary-color);
  border-width: 0px 0px 0.75px 0px;
}
#quoteRequestForm .form-control option, #priceAlarmForm .form-control option {
  background-color: var(--table-odd-background);
}

.dragger-item .oi, .dragger-disabled .oi {
  color: var(--default-font-color);
}

.sidebar__recent-actions .uitable table tbody tr:nth-of-type(odd), .sidebar__recent-actions .uitable table tbody tr:nth-of-type(even), .theme-light .sidebar__recent-actions .uitable table tbody tr:nth-of-type(odd), .theme-light .sidebar__recent-actions .uitable table tbody tr:nth-of-type(even) {
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
}

.form-control:focus {
  background-color: transparent;
  color: var(--default-font-color);
}

.sidebar {
  position: absolute;
  top: 0px;
  right: -15px;
  width: 300px;
  height: 100%;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-font-color);
  overflow-y: auto;
  transition: all 0.3s ease;
  z-index: 5;
}
.sidebar.react-draggable-transparent-selection {
  background-color: rgba(88, 95, 109, 0.3);
  z-index: 0;
}
.sidebar .innerSidebar {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  min-height: 100%;
  position: relative;
  padding-bottom: 130px;
}
.sidebar .innerSidebar .pcr-app {
  font-family: var(--client-font-name);
}
.sidebar__header .oi {
  cursor: pointer;
  font-size: 15px;
}
.sidebar__header .oi.oi-ellipses {
  transform: rotate(90deg);
}
.sidebar__content {
  position: relative;
  width: 100%;
}
.sidebar__topic {
  padding: 15px 0 0 0;
}
.sidebar__topic .subtitle {
  padding-bottom: 7.5px;
}
.sidebar__topic .sidebar__recent-actions {
  color: var(--default-font-color);
}
.sidebar__topic.logout {
  float: right;
}
.sidebar__topic.logout .logout-text, .sidebar__topic.logout a {
  cursor: pointer;
  margin-right: 10px;
}
.sidebar__topic.logout .logout-text span, .sidebar__topic.logout a span {
  color: var(--sidebar-font-color);
  font-weight: 500;
}
.sidebar__topic.logout a:hover {
  text-decoration: underline;
  color: var(--sidebar-font-color);
}
.sidebar__topic.logout a.oi:hover {
  text-decoration: none;
}
.sidebar__topic.logout span.countdown {
  margin-right: 10px;
}
.sidebar__footer {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.sidebar__footer a {
  cursor: pointer;
}
.sidebar__footer .logo {
  position: relative;
  width: 187px;
  height: fit-content;
}
.sidebar .threebar {
  cursor: pointer;
  position: fixed;
  right: 337.5px;
  height: 30px;
  width: 37.5px;
  transition: left 0.3s ease;
  z-index: 4;
  background-color: var(--sidebar-bg);
  border-top-left-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
}
.sidebar .threebar .bar {
  position: absolute;
  width: 22.5px;
  height: 3.75px;
  background: var(--sidebar-font-color);
  top: 12.75px;
  transition: all 0.3s ease;
  margin-left: 7.5px;
}
.sidebar .threebar .bar:nth-child(1) {
  transform: translateY(-150%);
}
.sidebar .threebar .bar:nth-child(3) {
  transform: translateY(150%);
}
.sidebar .threebar.cross .bar:nth-child(1) {
  transform: rotate(45deg);
}
.sidebar .threebar.cross .bar:nth-child(2) {
  opacity: 0;
}
.sidebar .threebar.cross .bar:nth-child(3) {
  transform: rotate(-45deg);
}
.sidebar.hidden {
  transform: translateX(100%);
  overflow-y: inherit;
}
.sidebar.hidden ~ .analytics-panel {
  width: calc(100% - 37.5px);
}
.sidebar ~ .analytics-panel {
  width: calc(100% - 367.5px);
}
.sidebar__recent-actions {
  position: relative;
  height: 187.5px;
  border-radius: 4px;
  padding: 7.5px;
  user-select: none;
  padding-top: 0;
  background-color: var(--table-odd-background);
}
.sidebar__recent-actions .tabs .nav-tabs {
  display: flex;
}
.sidebar__recent-actions .tabs .nav-tabs .nav-item {
  margin-bottom: 0px;
}
.sidebar__recent-actions .tabs .nav-tabs .nav-item.active {
  order: 6;
}
.sidebar__recent-actions .tabs .nav-tabs .nav-item .nav-link {
  padding: 0 0 7.5px 3.75px;
}
.sidebar__recent-actions .tabs .nav-tabs.nav-tabs-5 .nav-item.active {
  order: 5;
  width: 66%;
}
.sidebar__recent-actions .tabs .tab-content {
  height: calc(100% - 75px);
}
.sidebar__recent-actions .dragger {
  position: relative;
  width: 100%;
  height: 7.5px;
  cursor: move;
}
.sidebar__recent-actions .dragger:hover {
  background-color: var(--primary-color);
}
.sidebar__profile-views .profile-views__icons span, .sidebar__favorite-views .profile-views__icons span, .sidebar__bulk-views .profile-views__icons span {
  cursor: pointer;
  padding-left: 11.25px;
}
.sidebar__profile-views__list ul, .sidebar__favorite-views__list ul, .sidebar__bulk-views__list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar__profile-views__list ul li, .sidebar__favorite-views__list ul li, .sidebar__bulk-views__list ul li {
  color: var(--default-font-color);
  display: block;
  margin: 7.5px 0;
  padding: 7.5px;
  border-radius: 4px;
  cursor: pointer;
}
.sidebar__profile-views__list ul li label, .sidebar__favorite-views__list ul li label, .sidebar__bulk-views__list ul li label {
  cursor: pointer;
  margin: 0;
  padding-left: 7.5px;
}
.sidebar__profile-views__list ul li.more, .sidebar__favorite-views__list ul li.more, .sidebar__bulk-views__list ul li.more {
  padding: 0;
}
.sidebar__profile-views__list ul li.more, .sidebar__favorite-views__list ul li.more, .sidebar__bulk-views__list ul li.more {
  background-color: transparent;
  text-align: left;
  color: inherit;
}
.sidebar__profile-views__borderless-list table thead th, .sidebar__favorite-views__borderless-list table thead th, .sidebar__bulk-views__borderless-list table thead th {
  border: none;
}
.sidebar__profile-views__borderless-list table tbody tr td, .sidebar__favorite-views__borderless-list table tbody tr td, .sidebar__bulk-views__borderless-list table tbody tr td {
  padding: 7.5px;
  vertical-align: middle;
}
.sidebar__profile-views .blocked, .sidebar__favorite-views .blocked, .sidebar__bulk-views .blocked {
  cursor: not-allowed !important;
}
.sidebar__profile-views .blocked label, .sidebar__favorite-views .blocked label, .sidebar__bulk-views .blocked label {
  cursor: not-allowed !important;
}
.sidebar__favorite-views__list ul li:not(.more) {
  cursor: move;
}
.sidebar__favorite-views__list ul li:not(.more) label {
  cursor: move;
}
.sidebar-context-menu {
  position: absolute;
  border-radius: 5.25px;
  right: 22.5px;
  width: 135px;
  z-index: 3;
  background: var(--table-odd-background);
  border: var(--list-border);
}
.sidebar-context-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sidebar-context-menu ul li {
  padding: 7.5px;
  color: var(--default-font-color);
  border-bottom: var(--list-border);
  cursor: pointer;
}
.sidebar-context-menu ul li:hover {
  background-color: var(--hover-color);
}
.sidebar-context-menu-button:after {
  cursor: pointer;
  content: "⠇";
  font-size: 13.5px;
  margin-right: -7.5px;
}

.sidebar__profile-views table, .sidebar__favorite-views table {
  color: var(--default-font-color);
}
.sidebar__profile-views__borderless-list thead, .sidebar__profile-views__list thead, .sidebar__favorite-views__borderless-list thead, .sidebar__favorite-views__list thead {
  display: block;
}
.sidebar__profile-views__borderless-list tbody, .sidebar__profile-views__list tbody, .sidebar__favorite-views__borderless-list tbody, .sidebar__favorite-views__list tbody {
  display: block;
  max-height: calc(100vh - 232.5px);
  overflow-y: auto;
}
.sidebar__profile-views__borderless-list tbody tr td:first-child, .sidebar__profile-views__borderless-list thead th:first-child, .sidebar__profile-views__list tbody tr td:first-child, .sidebar__profile-views__list thead th:first-child, .sidebar__favorite-views__borderless-list tbody tr td:first-child, .sidebar__favorite-views__borderless-list thead th:first-child, .sidebar__favorite-views__list tbody tr td:first-child, .sidebar__favorite-views__list thead th:first-child {
  width: 300px;
  max-width: 300px;
  overflow-wrap: break-word;
}
.sidebar__profile-views__borderless-list tbody tr td:nth-child(2), .sidebar__profile-views__borderless-list thead th:nth-child(2), .sidebar__profile-views__list tbody tr td:nth-child(2), .sidebar__profile-views__list thead th:nth-child(2), .sidebar__favorite-views__borderless-list tbody tr td:nth-child(2), .sidebar__favorite-views__borderless-list thead th:nth-child(2), .sidebar__favorite-views__list tbody tr td:nth-child(2), .sidebar__favorite-views__list thead th:nth-child(2) {
  width: 90px;
}
.sidebar__profile-views__borderless-list tbody tr td:nth-child(3), .sidebar__profile-views__borderless-list thead th:nth-child(3), .sidebar__profile-views__list tbody tr td:nth-child(3), .sidebar__profile-views__list thead th:nth-child(3), .sidebar__favorite-views__borderless-list tbody tr td:nth-child(3), .sidebar__favorite-views__borderless-list thead th:nth-child(3), .sidebar__favorite-views__list tbody tr td:nth-child(3), .sidebar__favorite-views__list thead th:nth-child(3) {
  width: 90px;
}
.sidebar__profile-views__borderless-list .oi-trash, .sidebar__profile-views__list .oi-trash, .sidebar__favorite-views__borderless-list .oi-trash, .sidebar__favorite-views__list .oi-trash {
  cursor: pointer;
}
.sidebar__profile-views__borderless-list .select-item, .sidebar__profile-views__borderless-list .dragger-item, .sidebar__profile-views__borderless-list .blocked, .sidebar__profile-views__list .select-item, .sidebar__profile-views__list .dragger-item, .sidebar__profile-views__list .blocked, .sidebar__favorite-views__borderless-list .select-item, .sidebar__favorite-views__borderless-list .dragger-item, .sidebar__favorite-views__borderless-list .blocked, .sidebar__favorite-views__list .select-item, .sidebar__favorite-views__list .dragger-item, .sidebar__favorite-views__list .blocked {
  padding: 7.5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: var(--dock-background);
  color: var(--default-font-color);
}
.sidebar__profile-views__borderless-list .select-item .left-container, .sidebar__profile-views__borderless-list .dragger-item .left-container, .sidebar__profile-views__borderless-list .blocked .left-container, .sidebar__profile-views__list .select-item .left-container, .sidebar__profile-views__list .dragger-item .left-container, .sidebar__profile-views__list .blocked .left-container, .sidebar__favorite-views__borderless-list .select-item .left-container, .sidebar__favorite-views__borderless-list .dragger-item .left-container, .sidebar__favorite-views__borderless-list .blocked .left-container, .sidebar__favorite-views__list .select-item .left-container, .sidebar__favorite-views__list .dragger-item .left-container, .sidebar__favorite-views__list .blocked .left-container {
  display: flex;
  width: 95%;
}
.sidebar__profile-views__borderless-list .select-item .dashboard-name, .sidebar__profile-views__borderless-list .select-item .favorite-name, .sidebar__profile-views__borderless-list .dragger-item .dashboard-name, .sidebar__profile-views__borderless-list .dragger-item .favorite-name, .sidebar__profile-views__borderless-list .blocked .dashboard-name, .sidebar__profile-views__borderless-list .blocked .favorite-name, .sidebar__profile-views__list .select-item .dashboard-name, .sidebar__profile-views__list .select-item .favorite-name, .sidebar__profile-views__list .dragger-item .dashboard-name, .sidebar__profile-views__list .dragger-item .favorite-name, .sidebar__profile-views__list .blocked .dashboard-name, .sidebar__profile-views__list .blocked .favorite-name, .sidebar__favorite-views__borderless-list .select-item .dashboard-name, .sidebar__favorite-views__borderless-list .select-item .favorite-name, .sidebar__favorite-views__borderless-list .dragger-item .dashboard-name, .sidebar__favorite-views__borderless-list .dragger-item .favorite-name, .sidebar__favorite-views__borderless-list .blocked .dashboard-name, .sidebar__favorite-views__borderless-list .blocked .favorite-name, .sidebar__favorite-views__list .select-item .dashboard-name, .sidebar__favorite-views__list .select-item .favorite-name, .sidebar__favorite-views__list .dragger-item .dashboard-name, .sidebar__favorite-views__list .dragger-item .favorite-name, .sidebar__favorite-views__list .blocked .dashboard-name, .sidebar__favorite-views__list .blocked .favorite-name {
  padding-left: 7.5px;
  padding-right: 7.5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.sidebar__profile-views__borderless-list .select-item label, .sidebar__profile-views__borderless-list .dragger-item label, .sidebar__profile-views__borderless-list .blocked label, .sidebar__profile-views__list .select-item label, .sidebar__profile-views__list .dragger-item label, .sidebar__profile-views__list .blocked label, .sidebar__favorite-views__borderless-list .select-item label, .sidebar__favorite-views__borderless-list .dragger-item label, .sidebar__favorite-views__borderless-list .blocked label, .sidebar__favorite-views__list .select-item label, .sidebar__favorite-views__list .dragger-item label, .sidebar__favorite-views__list .blocked label {
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  margin: 0;
  display: inline-block;
  vertical-align: text-top;
}
.sidebar__profile-views__borderless-list .table-striped tbody tr .dragger-item, .sidebar__profile-views__list .table-striped tbody tr .dragger-item, .sidebar__favorite-views__borderless-list .table-striped tbody tr .dragger-item, .sidebar__favorite-views__list .table-striped tbody tr .dragger-item {
  border: 0.75px solid rgba(0, 0, 0, 0.4);
}
.sidebar__profile-views__borderless-list .modal-dialog, .sidebar__profile-views__list .modal-dialog, .sidebar__favorite-views__borderless-list .modal-dialog, .sidebar__favorite-views__list .modal-dialog {
  max-width: 525px;
}
.sidebar__profile-views__borderless-list .text-center label, .sidebar__profile-views__list .text-center label, .sidebar__favorite-views__borderless-list .text-center label, .sidebar__favorite-views__list .text-center label {
  margin-bottom: 0;
}
.sidebar__profile-views__borderless-list .oi, .sidebar__favorite-views__borderless-list .oi {
  align-self: center;
}
.sidebar__profile-views__recent-actions, .sidebar__favorite-views__recent-actions {
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
}
.sidebar__profile-views__recent-actions .tabs .nav-tabs .nav-item.inactive, .sidebar__profile-views__recent-actions .tabs .nav-tabs .nav-item:not(.active), .sidebar__favorite-views__recent-actions .tabs .nav-tabs .nav-item.inactive, .sidebar__favorite-views__recent-actions .tabs .nav-tabs .nav-item:not(.active) {
  border-right: var(--list-border);
}

.sidebar__favorite-views__borderless-list .dragger-item {
  cursor: move;
}
.sidebar__favorite-views__borderless-list .dragger-item label {
  cursor: move;
}
.sidebar__favorite-views__borderless-list .text-center label {
  margin-bottom: 0;
}

.sidebar__bulk-views__borderless-list thead {
  display: block;
}
.sidebar__bulk-views__borderless-list tbody {
  display: block;
  max-height: calc(100vh - 375px);
  overflow-y: auto;
}
.sidebar__bulk-views__borderless-list tbody tr td:first-child, .sidebar__bulk-views__borderless-list thead th:first-child {
  width: 225px;
  max-width: 225px;
  overflow-wrap: break-word;
}
.sidebar__bulk-views__borderless-list tbody tr td:nth-child(2), .sidebar__bulk-views__borderless-list thead th:nth-child(2) {
  width: 112.5px;
}

.modal-password-reset .btn-cancellation {
  background-color: rgb(191, 195, 204);
}
.modal-password-reset .btn-cancellation:hover {
  background-color: rgb(191, 195, 204);
  cursor: not-allowed;
}
.modal-password-reset .oi-x:hover {
  cursor: not-allowed;
}

.grid-icon {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  line-height: 9px;
  height: 20px;
  margin-right: 2px;
  background-color: transparent;
}
.grid-icon div {
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: var(--sidebar-font-color);
}
.grid-icon.grid-active div {
  background-color: var(--primary-color);
}

.grid-icon.grid-off {
  overflow: hidden;
  border: 2px solid var(--sidebar-font-color);
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 8px;
}
.grid-icon.grid-off div {
  border-bottom: 2px solid var(--sidebar-font-color);
  transform: rotate(-45deg);
  transform-origin: left;
  margin-right: 2px;
  margin-top: 13px;
  width: 23px;
}
.grid-icon.grid-off.grid-active {
  border-color: var(--primary-color);
}
.grid-icon.grid-off.grid-active div {
  border-color: var(--primary-color);
}

.dock__wrapper {
  box-sizing: border-box;
  position: absolute;
  border-radius: 4px;
  user-select: none;
  z-index: 1;
  background: var(--dock-background);
}
.dock__wrapper .react-resizable {
  transition: all 0.1s linear;
  overflow-y: auto;
}
.dock__wrapper .react-resizable-handle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 101%;
  height: 101%;
  cursor: nwse-resize;
  z-index: -1;
}
.dock__wrapper.cursor-both .react-resizable-handle {
  cursor: nwse-resize;
}
.dock__wrapper.cursor-x .react-resizable-handle {
  cursor: ew-resize;
}
.dock__wrapper.cursor-y .react-resizable-handle {
  cursor: ns-resize;
}
.dock__wrapper.react-draggable-dragging {
  box-shadow: 0px 0px 22.5px 0px rgb(0, 173, 151);
  z-index: 2;
  opacity: 0.75;
}
.dock__wrapper.react-draggable-dragging .dock__shadow {
  background-color: rgba(88, 95, 109, 0.3);
}
.dock__wrapper.react-draggable-dragging .cursor {
  background-color: var(--primary-color);
}
.dock__wrapper.react-draggable-resizing {
  opacity: 0.75;
}
.dock__wrapper.react-draggable-resizing .react-resizable {
  transition: width 0.1s linear;
}
.dock__wrapper.react-draggable-resizing .dock__shadow {
  z-index: 999;
}
.dock__wrapper.isColliding.allowed {
  box-shadow: 0px 0px 22.5px 0px rgb(0, 173, 151);
}
.dock__wrapper.isColliding.allowed .dock__shadow {
  background-color: rgb(0, 173, 151);
  opacity: 0.15;
}
.dock__wrapper.isColliding.disallowed, .dock__wrapper.isColliding.removing {
  box-shadow: 0px 0px 22.5px 0px rgb(223, 0, 0);
}
.dock__wrapper.isColliding.disallowed .dock__shadow, .dock__wrapper.isColliding.removing .dock__shadow {
  background-color: rgb(223, 0, 0);
  opacity: 0.15;
}
.dock__wrapper .cursor {
  display: block;
  position: absolute;
  width: 100%;
  height: 7.5px;
  top: 0;
  left: 0;
  cursor: move;
  z-index: 22;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dock__wrapper .cursor:hover {
  z-index: 26;
}
.dock__wrapper .remove {
  position: absolute;
  left: 3.75px;
  bottom: 0px;
  z-index: 2;
  border: none;
  background: transparent;
  cursor: pointer;
}
.dock__content {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 4px;
  border: var(--dock-border);
  background: var(--dock-background);
}
.dock__content .box {
  padding: 7.5px;
  padding-top: 0px;
}
.dock__content .cursor {
  background-color: var(--table-odd-background);
}
.dock__content .cursor:hover {
  background-color: var(--hover-color);
}
.dock__shadow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 0.75px dashed transparent;
  border-radius: 4px;
  background-color: rgba(88, 95, 109, 0.3);
  transition: all 0.1s linear;
}

.align-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tabs {
  overflow-y: hidden;
}
.tabs .nav-tabs {
  display: table;
  table-layout: fixed;
  border: none;
  width: 100%;
}
.tabs .nav-tabs .tabs__link {
  padding-right: 0.75px !important;
  padding-left: 0.75px !important;
}
.tabs .nav-tabs.narrow .active {
  width: 7.125rem;
}
.tabs__actions {
  padding: 0;
  margin: 0;
  display: none;
  list-style: none;
  position: absolute;
  right: 10px;
}
.tabs .tab-content {
  position: absolute;
  left: 0;
  height: calc(100% - 48.75px);
  padding: 0 7.5px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.nav-item, ul[role=tablist] {
  border: none;
}
.nav-item li, ul[role=tablist] li {
  font-weight: bold;
  margin: 0 6px 0 0;
}

ul[role=tablist] {
  width: 100%;
}

li[role=presentation] {
  text-align: center;
}

.nav-item, li[role=presentation] {
  display: inline-block;
  border-bottom: var(--list-border);
  color: var(--grey-font-color);
  display: inline-block;
  padding: 0px 15px;
}
.nav-item :hover, li[role=presentation] :hover {
  text-decoration: none;
}
.nav-item:not(:last-child), li[role=presentation]:not(:last-child) {
  border-right: none;
}
.nav-item a, li[role=presentation] a {
  font-weight: 400;
  color: var(--default-font-color);
}
.nav-item.active, li[role=presentation].active {
  color: var(--default-font-color);
  border-bottom: none;
  font-weight: bold;
}
.nav-item.active a, li[role=presentation].active a {
  color: var(--default-font-color);
}
.nav-item.active .tabs__actions, li[role=presentation].active .tabs__actions {
  display: inline-flex;
}
.nav-item.inactive, .nav-item:not(.active), li[role=presentation].inactive, li[role=presentation]:not(.active) {
  color: var(--grey-font-color);
}
.nav-item.inactive a, .nav-item:not(.active) a, li[role=presentation].inactive a, li[role=presentation]:not(.active) a {
  color: var(--default-font-color);
}
.nav-item:first-child .nav-link, li[role=presentation]:first-child .nav-link {
  border-left: 0;
  padding-left: 0;
}
.nav-item .nav-link, li[role=presentation] .nav-link {
  display: block;
  padding: 7.5px;
  border: none;
  background-color: transparent;
}
.nav-item .nav-link:hover, .nav-item .nav-link:focus, li[role=presentation] .nav-link:hover, li[role=presentation] .nav-link:focus {
  border: none;
}
.nav-item .nav-link .justify-content-between, li[role=presentation] .nav-link .justify-content-between {
  overflow: hidden;
}

.create-price-alarm {
  margin-bottom: 2px;
  color: white;
}

.sidebar__recent-actions .nav-item, .sidebar__recent-actions li[role=presentation] {
  padding: 0px 5px;
}
.sidebar__recent-actions .nav-item h3, .sidebar__recent-actions li[role=presentation] h3 {
  font-size: 13.5px;
}

.meet-table {
  height: 100%;
}
.meet-table .table-responsive {
  height: 100%;
  overflow-y: hidden;
}
.meet-table .table {
  table-layout: fixed;
  margin: 0;
  min-width: 100%;
  border-collapse: collapse;
  overflow-y: hidden;
  height: 100%;
}
.meet-table .table-header-title {
  text-overflow: ellipsis;
  overflow: hidden;
}
.meet-table .table-header-cell-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: -3.75px;
  padding: 3.75px;
  flex: 1 1;
  cursor: pointer;
}
.meet-table .table thead {
  background-color: var(--marketsheet-headers);
  position: sticky;
  width: 100%;
  display: table;
  table-layout: fixed;
}
.meet-table .table thead tr th {
  text-align: center;
  padding: 4px 4px;
  height: 28px;
  color: var(--default-font-color);
  font-size: 12px;
}
.meet-table .table thead tr th:first-of-type {
  text-align: left;
}
.meet-table .table thead tr th .table-header-title {
  text-transform: uppercase;
  font-weight: 500;
}
.meet-table .table tbody.emptyBody {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.meet-table .table tbody tr td {
  text-align: center;
}
.meet-table .table tbody tr td:first-of-type, .meet-table .table tbody tr td.contract {
  text-align: left;
}
.meet-table .user-select-none {
  user-select: none;
}
.meet-table .user-select-none :focus {
  outline: none;
}

.sidebar__recent-actions .meet-table .table.Order .contract, .sidebar__recent-actions .meet-table .table.Owntrade .contract, .sidebar__recent-actions .meet-table .table.Request .contract {
  width: 55%;
}
.sidebar__recent-actions .meet-table .table.Order .state, .sidebar__recent-actions .meet-table .table.Trade .state, .sidebar__recent-actions .meet-table .table.Owntrade .state, .sidebar__recent-actions .meet-table .table.Request .state, .sidebar__recent-actions .meet-table .table.Log .state {
  width: 15%;
}
.sidebar__recent-actions .meet-table .table.Order .level, .sidebar__recent-actions .meet-table .table.Trade .level, .sidebar__recent-actions .meet-table .table.Owntrade .level, .sidebar__recent-actions .meet-table .table.Request .level, .sidebar__recent-actions .meet-table .table.Log .level {
  width: 19%;
}
.sidebar__recent-actions .meet-table .table.Order .price,
.sidebar__recent-actions .meet-table .table.Order .quantity,
.sidebar__recent-actions .meet-table .table.Order .limit,
.sidebar__recent-actions .meet-table .table.Order .remainingQuantity, .sidebar__recent-actions .meet-table .table.Trade .price,
.sidebar__recent-actions .meet-table .table.Trade .quantity,
.sidebar__recent-actions .meet-table .table.Trade .limit,
.sidebar__recent-actions .meet-table .table.Trade .remainingQuantity, .sidebar__recent-actions .meet-table .table.Owntrade .price,
.sidebar__recent-actions .meet-table .table.Owntrade .quantity,
.sidebar__recent-actions .meet-table .table.Owntrade .limit,
.sidebar__recent-actions .meet-table .table.Owntrade .remainingQuantity, .sidebar__recent-actions .meet-table .table.Request .price,
.sidebar__recent-actions .meet-table .table.Request .quantity,
.sidebar__recent-actions .meet-table .table.Request .limit,
.sidebar__recent-actions .meet-table .table.Request .remainingQuantity, .sidebar__recent-actions .meet-table .table.Log .price,
.sidebar__recent-actions .meet-table .table.Log .quantity,
.sidebar__recent-actions .meet-table .table.Log .limit,
.sidebar__recent-actions .meet-table .table.Log .remainingQuantity {
  width: 16%;
}
.sidebar__recent-actions .meet-table .table.Order .paidGiven,
.sidebar__recent-actions .meet-table .table.Order .buySell,
.sidebar__recent-actions .meet-table .table.Order .buy, .sidebar__recent-actions .meet-table .table.Trade .paidGiven,
.sidebar__recent-actions .meet-table .table.Trade .buySell,
.sidebar__recent-actions .meet-table .table.Trade .buy, .sidebar__recent-actions .meet-table .table.Owntrade .paidGiven,
.sidebar__recent-actions .meet-table .table.Owntrade .buySell,
.sidebar__recent-actions .meet-table .table.Owntrade .buy, .sidebar__recent-actions .meet-table .table.Request .paidGiven,
.sidebar__recent-actions .meet-table .table.Request .buySell,
.sidebar__recent-actions .meet-table .table.Request .buy, .sidebar__recent-actions .meet-table .table.Log .paidGiven,
.sidebar__recent-actions .meet-table .table.Log .buySell,
.sidebar__recent-actions .meet-table .table.Log .buy {
  width: 13%;
}
.sidebar__recent-actions .meet-table .table.Order .timestamp, .sidebar__recent-actions .meet-table .table.Trade .timestamp, .sidebar__recent-actions .meet-table .table.Owntrade .timestamp, .sidebar__recent-actions .meet-table .table.Request .timestamp, .sidebar__recent-actions .meet-table .table.Log .timestamp {
  width: 19%;
}
.sidebar__recent-actions .meet-table .table.Order .time, .sidebar__recent-actions .meet-table .table.Trade .time, .sidebar__recent-actions .meet-table .table.Owntrade .time, .sidebar__recent-actions .meet-table .table.Request .time, .sidebar__recent-actions .meet-table .table.Log .time {
  width: 21%;
}
.sidebar__recent-actions .meet-table .table.Trade .contract {
  min-width: 68%;
}
.sidebar__recent-actions .meet-table .table.Trade .price,
.sidebar__recent-actions .meet-table .table.Trade .quantity {
  width: 16%;
}

.meet-table {
  /**
  <col class="traderPortfolioId">
  */
}
.meet-table .table.Log .level {
  width: 10%;
}
.meet-table .table.Log .time {
  width: 9%;
}
.meet-table .table.Trade .contract {
  width: 41%;
}
.meet-table .table.Trade .price,
.meet-table .table.Trade .quantity {
  width: 9%;
}
.meet-table .table.Trade .paidGiven {
  width: 5%;
}
.meet-table .table.Trade .qtyUnit {
  width: 6%;
}
.meet-table .table.Trade .initiator,
.meet-table .table.Trade .aggressor {
  width: 6%;
}
.meet-table .table.Trade .timestamp,
.meet-table .table.Trade .time,
.meet-table .table.Trade .date {
  width: 9%;
}
.meet-table .table.Owntrade .contract {
  width: 31%;
}
.meet-table .table.Owntrade .price,
.meet-table .table.Owntrade .quantity {
  width: 8%;
}
.meet-table .table.Owntrade .buy {
  width: 5%;
}
.meet-table .table.Owntrade .qtyUnit {
  width: 5%;
}
.meet-table .table.Owntrade .initiator,
.meet-table .table.Owntrade .aggressor {
  width: 6%;
}
.meet-table .table.Owntrade .timestamp,
.meet-table .table.Owntrade .time,
.meet-table .table.Owntrade .date {
  width: 9%;
}
.meet-table .table.Order .id {
  width: 5%;
}
.meet-table .table.Order .contract {
  width: 35%;
}
.meet-table .table.Order .limit,
.meet-table .table.Order .remainingQuantity,
.meet-table .table.Order .orderedQuantity {
  width: 7%;
}
.meet-table .table.Order .buySell {
  width: 5%;
}
.meet-table .table.Order .userId {
  width: 5%;
}
.meet-table .table.Order .timestamp,
.meet-table .table.Order .time,
.meet-table .table.Order .date {
  width: 8%;
}
.meet-table .table.Order .restriction {
  width: 5%;
}
.meet-table .table.Order .qtyUnit {
  width: 5%;
}
.meet-table .table.Request .id {
  min-width: 5%;
}
.meet-table .table.Request .contract {
  min-width: 20%;
}
.meet-table .table.Request .price,
.meet-table .table.Request .quantity {
  min-width: 5%;
}
.meet-table .table.Request .buy {
  width: 5%;
}
.meet-table .table.Request .requester,
.meet-table .table.Request .answerer {
  min-width: 10%;
}
.meet-table .table.Request .validFrom,
.meet-table .table.Request .validTo {
  min-width: 10%;
}
.meet-table .table.Request .meetState,
.meet-table .table.Request .comment,
.meet-table .table.Request .reference,
.meet-table .table.Request .requesterBusinessUnitId,
.meet-table .table.Request .requesterPortfolioId,
.meet-table .table.Request .traderBusinessUnitId,
.meet-table .table.Request .traderPortfolioId {
  min-width: 5%;
}
.meet-table .table.PriceAlarm .contract {
  min-width: 35%;
}
.meet-table .table.BulkError .bulkRow {
  width: 10%;
}
.meet-table .table.BulkError .error {
  width: 90%;
}
.meet-table .table.BulkOrder .date {
  width: 10%;
}
.meet-table .table.BulkOrder .timeShort {
  width: 10%;
}
.meet-table .table.BulkOrder .periodShort {
  width: 8%;
}
.meet-table .table.BulkOrder .grid {
  width: 18%;
}
.meet-table .table.BulkOrder .buySell {
  width: 8%;
}
.meet-table .table.BulkOrder .price {
  width: 10%;
}
.meet-table .table.BulkOrder .quantity {
  width: 10%;
}
.meet-table .table.BulkOrder .quantityUnit {
  width: 10%;
}
.meet-table .table.BulkOrder .delete {
  width: 8%;
}
.meet-table .table.BulkOrder .status {
  width: 8%;
}

.time,
.level {
  width: 60px;
}

.date {
  width: 75px;
}

.message {
  text-align: left !important;
}

#meet-tooltip {
  width: 100%;
}
#meet-tooltip .popover-content table {
  width: 100%;
}
#meet-tooltip .meet-tooltip-name {
  padding-right: 7.5px;
}
#meet-tooltip .meet-tooltip-value.contract {
  font-weight: bold;
  display: block;
}

.trade-db-controls {
  height: 100%;
}
.trade-db-controls table.Trade, .trade-db-controls table.Owntrade {
  height: calc(100% - 22px);
}

ul.trade-limit-actions {
  list-style: none;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0px;
  font-size: 12px;
}
ul.trade-limit-actions li {
  float: left;
  padding: 8px 25px;
  cursor: pointer;
  height: 30px;
  font-weight: 500;
}
ul.trade-limit-actions li.active {
  color: var(--primary-color);
}
ul.trade-limit-actions li.trade-limit-setting {
  float: right;
  padding: 8px 5px;
}

.sidebar__recent-actions ul.trade-limit-actions li {
  color: var(--grey-font-color);
  padding: 8px 10px;
  height: 23px;
}
.sidebar__recent-actions ul.trade-limit-actions li.active {
  color: var(--primary-color);
}
.sidebar__recent-actions .meet-table thead, .sidebar__recent-actions .meet-table thead th {
  background: transparent;
}

.meet-icon {
  width: 12px;
  height: auto;
}
.meet-icon svg {
  fill: var(--secondary-color);
}

.messages {
  position: relative;
  width: 100%;
}
.messages__content {
  display: block;
  padding: 3.75px 18.75px 3.75px 7.5px;
  margin: 7.5px 0;
  border-radius: 5.25px;
}
.messages__content.error {
  background: rgb(248, 215, 218);
  color: rgb(114, 28, 36);
}
.messages__content .close-btn {
  position: absolute;
  right: 7.5px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.modal.meet-modal {
  opacity: 1;
  display: block;
  /** Position of the modal window on the screen. */
}
.modal.meet-modal .modal-dialog {
  position: fixed;
  top: 0;
  display: inline-block;
  margin: 0;
  width: 100%;
  height: 100%;
}
.modal.meet-modal .modal-dialog .modal-header {
  padding: 0;
  border: none;
  color: var(--default-font-color);
}
.modal.meet-modal .modal-dialog .modal-header__actions button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.modal.meet-modal .modal-dialog .modal-header__actions button:focus, .modal.meet-modal .modal-dialog .modal-header__actions button:active {
  outline: none;
}
.modal.meet-modal .modal-dialog .modal-header__actions button .oi {
  color: var(--default-font-color) !important;
}
.modal.meet-modal .modal-dialog .modal-body {
  padding: 0;
  padding-top: 15px;
  color: var(--default-font-color);
}
.modal.meet-modal .modal-dialog .modal-content {
  padding: 15px;
  text-align: initial;
  background-color: var(--dock-background);
}
.modal.meet-modal .modal-dialog .modal-footer {
  border: none;
  padding: 0;
  padding-top: 15px;
  min-height: 37.5px;
}
.modal.meet-modal .modal-dialog .modal-close {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.modal.meet-modal .modal.meet-modal .modal-header__actions .change-position {
  display: none;
}
.modal.meet-modal:not(.position-left):not(.position-right) {
  display: flex;
  justify-content: center;
}
.modal.meet-modal.position-right .modal-dialog {
  right: 0;
}
.modal.meet-modal:not(.position-top):not(.position-bottom) .modal-dialog {
  display: flex;
  align-items: center;
}
.modal.meet-modal.position-bottom .modal-content {
  position: absolute;
  bottom: 0;
}

.position-picker {
  text-align: center;
  color: var(--default-font-color);
  background: var(--dock-background);
}
.position-picker .modal-header {
  padding: 0;
  border: none;
}
.position-picker .modal-header button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.position-picker .modal-header button:focus, .position-picker .modal-header button:active {
  outline: none;
}
.position-picker .modal-body {
  padding: 0;
  padding-top: 15px;
}
.position-picker .modal-body button {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 0;
  background: var(--dock-background);
}
.position-picker .modal-body button .rotate-45 {
  transform: rotate(45deg);
}
.position-picker .oi:not(.active) {
  color: rgb(53, 60, 73);
}

.meet-notifications {
  position: fixed;
  right: 0;
  bottom: 15px;
  z-index: 999;
}
.meet-notifications .meet-notification {
  padding: 15px 30px 15px 15px;
  border: none;
  animation: 0.3s ease-out 0s 1 slideInFromRight;
  cursor: pointer;
}
.meet-notifications .meet-notification.confirmed {
  animation: none;
  cursor: default;
}
.meet-notifications .meet-notification.alert-info {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 173, 151);
}
.meet-notifications .meet-notification.alert-warning {
  color: rgb(255, 255, 255);
  background-color: rgb(255, 169, 100);
}
.meet-notifications .meet-notification .notification-confirmation {
  display: block;
  margin-left: 20px;
  float: right;
  margin-top: -3px;
  padding: 2px 5px 0px 5px;
  border: 0.75px solid rgb(255, 255, 255);
  border-radius: 4px;
  cursor: pointer;
}
.meet-notifications__modal .modal.fade {
  opacity: 1;
  background-color: rgba(88, 95, 109, 0.3);
}
.meet-notifications__modal .modal-dialog .modal-body .form-control {
  display: inline-block;
  margin: auto 7.5px;
  width: 2.25rem;
  height: inherit;
  padding: 0;
  line-height: 1;
  text-align: center;
}
.meet-notifications__modal .modal-dialog .modal-body .form-control.select-input-outline {
  color: rgb(88, 95, 109);
  text-align-last: center;
  font-weight: 300;
  border: none;
  border-radius: 0;
  border-bottom: 0.75px solid var(--primary-color);
  background: transparent;
  width: 6.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.75px;
  text-overflow: "";
}
.meet-notifications__modal .modal-dialog .modal-body .form-control.select-input-outline:focus {
  outline: none !important;
  box-shadow: inherit;
}
.meet-notifications__modal .modal-dialog .modal-body input[type=number]::-webkit-inner-spin-button,
.meet-notifications__modal .modal-dialog .modal-body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideOutFromRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.meet-settings__modal .modal.fade, .meet-bulk__modal .modal.fade {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.meet-settings__modal .modal-dialog .modal-body .oi, .meet-bulk__modal .modal-dialog .modal-body .oi {
  cursor: pointer;
  margin-top: 6px;
  color: var(--default-font-color);
}
.meet-settings__modal .modal-dialog .modal-body .oi:hover, .meet-bulk__modal .modal-dialog .modal-body .oi:hover {
  color: var(--hover-color);
}
.meet-settings__modal .modal-dialog .modal-body .nav-item, .meet-settings__modal .modal-dialog .modal-body ul[role=tablist], .meet-bulk__modal .modal-dialog .modal-body .nav-item, .meet-bulk__modal .modal-dialog .modal-body ul[role=tablist] {
  border: none;
}
.meet-settings__modal .modal-dialog .modal-body .nav-item li, .meet-settings__modal .modal-dialog .modal-body ul[role=tablist] li, .meet-bulk__modal .modal-dialog .modal-body .nav-item li, .meet-bulk__modal .modal-dialog .modal-body ul[role=tablist] li {
  font-weight: bold;
  margin: 0 0 0 0;
}
.meet-settings__modal .modal-dialog input[type=color], .meet-bulk__modal .modal-dialog input[type=color] {
  cursor: pointer;
  -webkit-appearance: none;
  border: 0.75px solid var(--default-font-color);
}
.meet-settings__modal .modal-dialog input[type=color]:focus, .meet-bulk__modal .modal-dialog input[type=color]:focus {
  outline: none;
}
.meet-settings__modal .modal-dialog input[type=color]::-webkit-color-swatch-wrapper, .meet-bulk__modal .modal-dialog input[type=color]::-webkit-color-swatch-wrapper {
  padding: 0;
}
.meet-settings__modal .modal-dialog input[type=color]::-webkit-color-swatch, .meet-bulk__modal .modal-dialog input[type=color]::-webkit-color-swatch {
  border: 0.75px solid rgb(88, 95, 109);
}
.meet-settings__modal .modal-dialog input[type=text], .meet-bulk__modal .modal-dialog input[type=text] {
  height: 40px;
  color: var(--default-font-color);
}
.meet-settings__modal .modal-dialog .pcr-app input, .meet-bulk__modal .modal-dialog .pcr-app input {
  color: rgb(53, 60, 73);
}
.meet-settings__modal .modal-dialog .form-group label, .meet-bulk__modal .modal-dialog .form-group label {
  height: 40px;
}
.meet-settings__modal .modal-dialog .col-3, .meet-bulk__modal .modal-dialog .col-3 {
  padding-right: 18.75px;
}
.meet-settings__modal .modal-dialog .col-1, .meet-bulk__modal .modal-dialog .col-1 {
  padding-left: 0;
}
.meet-settings__modal .modal-dialog .nav-item, .meet-settings__modal .modal-dialog ul[role=tablist], .meet-bulk__modal .modal-dialog .nav-item, .meet-bulk__modal .modal-dialog ul[role=tablist] {
  width: 90%;
}
.meet-settings__modal .modal-dialog .nav-item li, .meet-settings__modal .modal-dialog ul[role=tablist] li, .meet-bulk__modal .modal-dialog .nav-item li, .meet-bulk__modal .modal-dialog ul[role=tablist] li {
  width: 50%;
}

.meet-settings__modal div.settings-scroll {
  max-height: 550px;
  min-height: 550px;
}

div.settings-scroll {
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pickr {
  width: 100%;
}
.pickr .pcr-button {
  width: 100%;
  border: 0.75px solid rgb(0, 0, 0);
}

.pcr-app.visible {
  margin-left: 0 !important;
  position: absolute;
  left: 30% !important;
  transform: translateY(-50%);
}

.config-tab .oi-action-undo {
  margin-top: 0 !important;
}
.config-tab .number input, .config-tab text input {
  text-align: right;
  padding-right: 7.5px;
}
.config-tab .selection input {
  padding: 7.5px;
  text-align: center;
}

.btn-group-toggle label.btn {
  padding: 3, 75px 7.5px;
  background-color: transparent;
  color: var(--default-font-color);
}
.btn-group-toggle label.btn.option1.active {
  background-color: rgb(241, 146, 122);
}
.btn-group-toggle label.btn.option2.active {
  background-color: rgb(170, 249, 132);
}

.favorite__new {
  margin-top: 7.5px;
}
.favorite__new .form-group {
  margin-bottom: 7.5px;
}
.favorite__new .form-group input {
  height: 40px;
  background: transparent;
}
.favorite__results {
  position: relative;
  overflow-y: auto;
  width: 93%;
  margin: auto;
}
.favorite__results ul {
  padding: 0;
  margin: 5px 0px;
  list-style: none;
  color: rgb(53, 60, 73);
  height: auto;
  max-height: 150px;
  font-size: 13.5px;
}
.favorite__results ul li {
  cursor: pointer;
  border: 0.75px solid rgb(191, 195, 204);
  border-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 7.5px;
  margin: 2px 0px;
}
.favorite__results ul li label {
  margin: 0;
  cursor: pointer;
}
.favorite__results ul li .favorite-name {
  padding-left: 7.5px;
  padding-right: 7.5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.favorite__results ul li:hover {
  background-color: rgba(88, 95, 109, 0.3);
}
.favorite__results ul .blocked {
  cursor: not-allowed;
}
.favorite table {
  color: var(--default-font-color);
}
.favorite__results {
  background-color: transparent;
  color: var(--default-font-color);
}
.favorite__new .form-group input {
  color: var(--default-font-color);
}

.modal-favorite-more .modal-content {
  width: auto;
}

.dragger-disabled {
  margin-top: 7.5px;
}

.oi {
  position: relative;
  top: 0.75px;
  display: inline-block;
  speak: none;
  font-family: "Icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oi:empty:before {
  width: 1em;
  text-align: center;
  box-sizing: content-box;
}

.oi.oi-align-center:before {
  text-align: center;
}

.oi.oi-align-left:before {
  text-align: left;
}

.oi.oi-align-right:before {
  text-align: right;
}

.oi.oi-flip-horizontal:before {
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.oi.oi-flip-vertical:before {
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(-1, 1);
  transform: scale(1, -1);
}

.oi.oi-flip-horizontal-vertical:before {
  -webkit-transform: scale(-1, -1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, -1);
}

.oi-account-login:before {
  content: "\e000";
}

.oi-account-logout:before {
  content: "\e001";
}

.oi-action-redo:before {
  content: "\e002";
}

.oi-action-undo:before {
  content: "\e003";
}

.oi-align-center:before {
  content: "\e004";
}

.oi-align-left:before {
  content: "\e005";
}

.oi-align-right:before {
  content: "\e006";
}

.oi-aperture:before {
  content: "\e007";
}

.oi-arrow-bottom:before {
  content: "\e008";
}

.oi-arrow-circle-bottom:before {
  content: "\e009";
}

.oi-arrow-circle-left:before {
  content: "\e00a";
}

.oi-arrow-circle-right:before {
  content: "\e00b";
}

.oi-arrow-circle-top:before {
  content: "\e00c";
}

.oi-arrow-left:before {
  content: "\e00d";
}

.oi-arrow-right:before {
  content: "\e00e";
}

.oi-arrow-thick-bottom:before {
  content: "\e00f";
}

.oi-arrow-thick-left:before {
  content: "\e010";
}

.oi-arrow-thick-right:before {
  content: "\e011";
}

.oi-arrow-thick-top:before {
  content: "\e012";
}

.oi-arrow-top:before {
  content: "\e013";
}

.oi-audio-spectrum:before {
  content: "\e014";
}

.oi-audio:before {
  content: "\e015";
}

.oi-badge:before {
  content: "\e016";
}

.oi-ban:before {
  content: "\e017";
}

.oi-bar-chart:before {
  content: "\e018";
}

.oi-basket:before {
  content: "\e019";
}

.oi-battery-empty:before {
  content: "\e01a";
}

.oi-battery-full:before {
  content: "\e01b";
}

.oi-beaker:before {
  content: "\e01c";
}

.oi-bell:before {
  content: "\e01d";
}

.oi-bluetooth:before {
  content: "\e01e";
}

.oi-bold:before {
  content: "\e01f";
}

.oi-bolt:before {
  content: "\e020";
}

.oi-book:before {
  content: "\e021";
}

.oi-bookmark:before {
  content: "\e022";
}

.oi-box:before {
  content: "\e023";
}

.oi-briefcase:before {
  content: "\e024";
}

.oi-british-pound:before {
  content: "\e025";
}

.oi-browser:before {
  content: "\e026";
}

.oi-brush:before {
  content: "\e027";
}

.oi-bug:before {
  content: "\e028";
}

.oi-bullhorn:before {
  content: "\e029";
}

.oi-calculator:before {
  content: "\e02a";
}

.oi-calendar:before {
  content: "\e02b";
}

.oi-camera-slr:before {
  content: "\e02c";
}

.oi-caret-bottom:before {
  content: "\e02d";
}

.oi-caret-left:before {
  content: "\e02e";
}

.oi-caret-right:before {
  content: "\e02f";
}

.oi-caret-top:before {
  content: "\e030";
}

.oi-cart:before {
  content: "\e031";
}

.oi-chat:before {
  content: "\e032";
}

.oi-check:before {
  content: "\e033";
}

.oi-chevron-bottom:before {
  content: "\e034";
}

.oi-chevron-left:before {
  content: "\e035";
}

.oi-chevron-right:before {
  content: "\e036";
}

.oi-chevron-top:before {
  content: "\e037";
}

.oi-circle-check:before {
  content: "\e038";
}

.oi-circle-x:before {
  content: "\e039";
}

.oi-clipboard:before {
  content: "\e03a";
}

.oi-clock:before {
  content: "\e03b";
}

.oi-cloud-download:before {
  content: "\e03c";
}

.oi-cloud-upload:before {
  content: "\e03d";
}

.oi-cloud:before {
  content: "\e03e";
}

.oi-cloudy:before {
  content: "\e03f";
}

.oi-code:before {
  content: "\e040";
}

.oi-cog:before {
  content: "\e041";
}

.oi-collapse-down:before {
  content: "\e042";
}

.oi-collapse-left:before {
  content: "\e043";
}

.oi-collapse-right:before {
  content: "\e044";
}

.oi-collapse-up:before {
  content: "\e045";
}

.oi-command:before {
  content: "\e046";
}

.oi-comment-square:before {
  content: "\e047";
}

.oi-compass:before {
  content: "\e048";
}

.oi-contrast:before {
  content: "\e049";
}

.oi-copywriting:before {
  content: "\e04a";
}

.oi-credit-card:before {
  content: "\e04b";
}

.oi-crop:before {
  content: "\e04c";
}

.oi-dashboard:before {
  content: "\e04d";
}

.oi-data-transfer-download:before {
  content: "\e04e";
}

.oi-data-transfer-upload:before {
  content: "\e04f";
}

.oi-delete:before {
  content: "\e050";
}

.oi-dial:before {
  content: "\e051";
}

.oi-document:before {
  content: "\e052";
}

.oi-dollar:before {
  content: "\e053";
}

.oi-double-quote-sans-left:before {
  content: "\e054";
}

.oi-double-quote-sans-right:before {
  content: "\e055";
}

.oi-double-quote-serif-left:before {
  content: "\e056";
}

.oi-double-quote-serif-right:before {
  content: "\e057";
}

.oi-droplet:before {
  content: "\e058";
}

.oi-eject:before {
  content: "\e059";
}

.oi-elevator:before {
  content: "\e05a";
}

.oi-ellipses:before {
  content: "\e05b";
}

.oi-envelope-closed:before {
  content: "\e05c";
}

.oi-envelope-open:before {
  content: "\e05d";
}

.oi-euro:before {
  content: "\e05e";
}

.oi-excerpt:before {
  content: "\e05f";
}

.oi-expand-down:before {
  content: "\e060";
}

.oi-expand-left:before {
  content: "\e061";
}

.oi-expand-right:before {
  content: "\e062";
}

.oi-expand-up:before {
  content: "\e063";
}

.oi-external-link:before {
  content: "\e064";
}

.oi-eye:before {
  content: "\e065";
}

.oi-eyedropper:before {
  content: "\e066";
}

.oi-file:before {
  content: "\e067";
}

.oi-fire:before {
  content: "\e068";
}

.oi-flag:before {
  content: "\e069";
}

.oi-flash:before {
  content: "\e06a";
}

.oi-folder:before {
  content: "\e06b";
}

.oi-fork:before {
  content: "\e06c";
}

.oi-fullscreen-enter:before {
  content: "\e06d";
}

.oi-fullscreen-exit:before {
  content: "\e06e";
}

.oi-globe:before {
  content: "\e06f";
}

.oi-graph:before {
  content: "\e070";
}

.oi-grid-four-up:before {
  content: "\e071";
}

.oi-grid-three-up:before {
  content: "\e072";
}

.oi-grid-two-up:before {
  content: "\e073";
}

.oi-hard-drive:before {
  content: "\e074";
}

.oi-header:before {
  content: "\e075";
}

.oi-headphones:before {
  content: "\e076";
}

.oi-heart:before {
  content: "\e077";
}

.oi-home:before {
  content: "\e078";
}

.oi-image:before {
  content: "\e079";
}

.oi-inbox:before {
  content: "\e07a";
}

.oi-infinity:before {
  content: "\e07b";
}

.oi-info:before {
  content: "\e07c";
}

.oi-italic:before {
  content: "\e07d";
}

.oi-justify-center:before {
  content: "\e07e";
}

.oi-justify-left:before {
  content: "\e07f";
}

.oi-justify-right:before {
  content: "\e080";
}

.oi-key:before {
  content: "\e081";
}

.oi-laptop:before {
  content: "\e082";
}

.oi-layers:before {
  content: "\e083";
}

.oi-lightbulb:before {
  content: "\e084";
}

.oi-link-broken:before {
  content: "\e085";
}

.oi-link-intact:before {
  content: "\e086";
}

.oi-list-rich:before {
  content: "\e087";
}

.oi-list:before {
  content: "\e088";
}

.oi-location:before {
  content: "\e089";
}

.oi-lock-locked:before {
  content: "\e08a";
}

.oi-lock-unlocked:before {
  content: "\e08b";
}

.oi-loop-circular:before {
  content: "\e08c";
}

.oi-loop-square:before {
  content: "\e08d";
}

.oi-loop:before {
  content: "\e08e";
}

.oi-magnifying-glass:before {
  content: "\e08f";
}

.oi-map-marker:before {
  content: "\e090";
}

.oi-map:before {
  content: "\e091";
}

.oi-media-pause:before {
  content: "\e092";
}

.oi-media-play:before {
  content: "\e093";
}

.oi-media-record:before {
  content: "\e094";
}

.oi-media-skip-backward:before {
  content: "\e095";
}

.oi-media-skip-forward:before {
  content: "\e096";
}

.oi-media-step-backward:before {
  content: "\e097";
}

.oi-media-step-forward:before {
  content: "\e098";
}

.oi-media-stop:before {
  content: "\e099";
}

.oi-medical-cross:before {
  content: "\e09a";
}

.oi-menu:before {
  content: "\e09b";
}

.oi-microphone:before {
  content: "\e09c";
}

.oi-minus:before {
  content: "\e09d";
}

.oi-monitor:before {
  content: "\e09e";
}

.oi-moon:before {
  content: "\e09f";
}

.oi-move:before {
  content: "\e0a0";
}

.oi-musical-note:before {
  content: "\e0a1";
}

.oi-paperclip:before {
  content: "\e0a2";
}

.oi-pencil:before {
  content: "\e0a3";
}

.oi-people:before {
  content: "\e0a4";
}

.oi-person:before {
  content: "\e0a5";
}

.oi-phone:before {
  content: "\e0a6";
}

.oi-pie-chart:before {
  content: "\e0a7";
}

.oi-pin:before {
  content: "\e0a8";
}

.oi-play-circle:before {
  content: "\e0a9";
}

.oi-plus:before {
  content: "\e0aa";
}

.oi-power-standby:before {
  content: "\e0ab";
}

.oi-print:before {
  content: "\e0ac";
}

.oi-project:before {
  content: "\e0ad";
}

.oi-pulse:before {
  content: "\e0ae";
}

.oi-puzzle-piece:before {
  content: "\e0af";
}

.oi-question-mark:before {
  content: "\e0b0";
}

.oi-rain:before {
  content: "\e0b1";
}

.oi-random:before {
  content: "\e0b2";
}

.oi-reload:before {
  content: "\e0b3";
}

.oi-resize-both:before {
  content: "\e0b4";
}

.oi-resize-height:before {
  content: "\e0b5";
}

.oi-resize-width:before {
  content: "\e0b6";
}

.oi-rss-alt:before {
  content: "\e0b7";
}

.oi-rss:before {
  content: "\e0b8";
}

.oi-script:before {
  content: "\e0b9";
}

.oi-share-boxed:before {
  content: "\e0ba";
}

.oi-share:before {
  content: "\e0bb";
}

.oi-shield:before {
  content: "\e0bc";
}

.oi-signal:before {
  content: "\e0bd";
}

.oi-signpost:before {
  content: "\e0be";
}

.oi-sort-ascending:before {
  content: "\e0bf";
}

.oi-sort-descending:before {
  content: "\e0c0";
}

.oi-spreadsheet:before {
  content: "\e0c1";
}

.oi-star:before {
  content: "\e0c2";
}

.oi-sun:before {
  content: "\e0c3";
}

.oi-tablet:before {
  content: "\e0c4";
}

.oi-tag:before {
  content: "\e0c5";
}

.oi-tags:before {
  content: "\e0c6";
}

.oi-target:before {
  content: "\e0c7";
}

.oi-task:before {
  content: "\e0c8";
}

.oi-terminal:before {
  content: "\e0c9";
}

.oi-text:before {
  content: "\e0ca";
}

.oi-thumb-down:before {
  content: "\e0cb";
}

.oi-thumb-up:before {
  content: "\e0cc";
}

.oi-timer:before {
  content: "\e0cd";
}

.oi-transfer:before {
  content: "\e0ce";
}

.oi-trash:before {
  content: "\e0cf";
}

.oi-underline:before {
  content: "\e0d0";
}

.oi-vertical-align-bottom:before {
  content: "\e0d1";
}

.oi-vertical-align-center:before {
  content: "\e0d2";
}

.oi-vertical-align-top:before {
  content: "\e0d3";
}

.oi-video:before {
  content: "\e0d4";
}

.oi-volume-high:before {
  content: "\e0d5";
}

.oi-volume-low:before {
  content: "\e0d6";
}

.oi-volume-off:before {
  content: "\e0d7";
}

.oi-warning:before {
  content: "\e0d8";
}

.oi-wifi:before {
  content: "\e0d9";
}

.oi-wrench:before {
  content: "\e0da";
}

.oi-x:before {
  content: "\e0db";
}

.oi-yen:before {
  content: "\e0dc";
}

.oi-zoom-in:before {
  content: "\e0dd";
}

.oi-zoom-out:before {
  content: "\e0de";
}

.login-form {
  width: 262.5px;
  min-height: 262.5px;
  align-self: center;
  border-radius: 4px;
  padding: 22.5px;
  background-color: rgb(255, 255, 255);
}
.login-form .form-control {
  border-bottom: 0.75px solid var(--secondary-color);
}
.login-form .form-control :focus {
  border-color: var(--secondary-color);
}
.login-form .login-error {
  color: var(--secondary-color);
}
.login-form .alert {
  z-index: 1;
}
.login-form form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-form .logo {
  position: relative;
  text-align: center;
  width: 100%;
}
.login-form .logo .login-title {
  width: 100%;
  text-align: center;
}
.login-form .logo img {
  max-width: 227.5px;
  max-height: 262.5px;
}

.login-box {
  width: 262.5px;
  align-self: center;
  border-radius: 4px;
  padding: 22.5px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.spinner-login {
  margin-top: 40px;
  text-align: center;
}

.home__container {
  position: relative;
}
.home__container .docks__wrapper {
  position: relative;
  width: 100%;
  margin: 7.5px 0;
  height: calc(100% - 20px);
}
.home__container .disconnect-fade {
  background-color: rgba(88, 95, 109, 0.3);
  cursor: wait;
}

#cancel-drag-area {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 26.25px;
  left: 26.25px;
  text-align: center;
  z-index: 999;
}
#cancel-drag-area .oi {
  font-size: 15px;
}

.watermark {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.confirmation-block p {
  color: var(--default-font-color);
  font-size: 0.75rem;
}
.confirmation-block .d-flex {
  justify-content: center;
}

.modal-profile .reserved-name {
  color: rgb(248, 86, 86);
  padding: 4.5px 9px;
}

.profile__dropdown {
  background-color: var(--dock-background);
  position: relative;
  display: none;
  font-size: 13.5px;
  max-height: 500px;
  width: 100%;
  z-index: 1;
}
.profile__dropdown.visible {
  display: block;
}
.profile__results {
  position: relative;
  background-color: var(--dock-background);
  overflow-y: auto;
  width: 93%;
  margin: auto;
}
.profile__results ul {
  padding: 0;
  margin: 5px 0px;
  list-style: none;
  color: var(--default-font-color);
  height: auto;
  max-height: 150px;
  font-size: 13.5px;
}
.profile__results ul li {
  cursor: pointer;
  border: var(--list-border);
  border-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 7.5px;
  margin: 2px 0px;
}
.profile__results ul li label {
  margin: 0;
  cursor: pointer;
}
.profile__results ul li .dashboard-name {
  padding-left: 7.5px;
  padding-right: 7.5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.profile__results ul li:hover {
  background-color: rgba(88, 95, 109, 0.3);
}
.profile__results ul .blocked {
  cursor: not-allowed;
}

#context-menu {
  position: absolute;
  background: rgb(255, 255, 255);
  border: 0.75px solid rgb(191, 195, 204);
  top: 0;
  left: 0;
  width: 75px;
  z-index: 4;
}
#context-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#context-menu ul li {
  padding: 7.5px;
  cursor: pointer;
}
#context-menu ul li:not(:first-child) {
  border-bottom: 0.75px solid rgb(191, 195, 204);
}
#context-menu ul li:hover {
  background-color: rgb(232, 233, 237);
}

.analytics-panel {
  position: absolute;
  top: -9px;
  left: 0;
  width: 100%;
  z-index: 5;
  transition: width 0.3s ease;
  box-sizing: border-box;
}
.analytics-panel__content {
  position: relative;
  height: auto;
  overflow: hidden;
  border-bottom-right-radius: 4px;
  transition: height 0.1s linear;
  background-color: var(--dock-background);
}
.analytics-panel__content .info-panel {
  position: relative;
  width: 100%;
  height: 100%;
}
.analytics-panel__content .info-panel .quadrants-preview {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 7.5px;
}
.analytics-panel__content .info-panel .quadrants-preview .quadrant {
  position: relative;
  width: 50%;
  height: 50%;
  float: left;
  border: var(--list-border);
  text-align: center;
}
.analytics-panel__content .uitable .search,
.analytics-panel__content .uitable .table-visibility-filter {
  position: fixed;
  top: 50;
  width: calc(100% - 20px);
}
.analytics-panel__content .uitable__actions {
  position: fixed;
  padding: 0;
}
.analytics-panel .icons-panel {
  position: absolute;
}
.analytics-panel .icons-panel .drag-icon,
.analytics-panel .icons-panel .settings-icon {
  width: 33.75px;
  background-color: var(--secondary-color);
  padding: 7.5px;
}
.analytics-panel .icons-panel .drag-icon .oi,
.analytics-panel .icons-panel .settings-icon .oi {
  color: rgb(255, 255, 255);
  font-size: 15px;
}
.analytics-panel .icons-panel .drag-icon.drag-icon,
.analytics-panel .icons-panel .settings-icon.drag-icon {
  cursor: ns-resize;
}
.analytics-panel .icons-panel .drag-icon.settings-icon,
.analytics-panel .icons-panel .settings-icon.settings-icon {
  cursor: pointer;
}
.analytics-panel .icons-panel .drag-icon.settings-icon.active,
.analytics-panel .icons-panel .settings-icon.settings-icon.active {
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}
.analytics-panel .icons-panel .active-background {
  background-color: var(--active-color);
}
.analytics-panel .analytics-quadrants-panel {
  position: absolute;
}
.analytics-panel__settings {
  position: fixed;
  display: none;
  left: 84px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.analytics-panel__settings.expanded {
  display: block;
}
.analytics-panel__settings .quadrants .quadrant,
.analytics-panel__settings .quadrants .action-block {
  padding: 3.75px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: var(--list-border);
  background: var(--table-even-background);
  color: var(--default-font-color);
}
.analytics-panel__settings .quadrants .quadrant.active, .analytics-panel__settings .quadrants .quadrant:hover,
.analytics-panel__settings .quadrants .action-block.active,
.analytics-panel__settings .quadrants .action-block:hover {
  background: var(--hover-color);
}
.analytics-panel__settings .quadrants .action-block {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.analytics-panel__settings .panels {
  background: var(--table-even-background);
  color: var(--default-font-color);
}
.analytics-panel__settings .panels .panel-column {
  width: 100%;
  cursor: pointer;
  padding: 7.5px;
  border: var(--list-border);
  min-width: 112.5px;
}
.analytics-panel__settings .panels .panel-column:hover {
  background: var(--hover-color);
}
.analytics-panel__settings .panels .panel-column.exists {
  cursor: no-drop;
  background: var(--active-color);
}
.analytics-panel .resizer {
  position: absolute;
  border-color: var(--table-even-background);
  z-index: 5;
}
.analytics-panel .resizer.resizer-x {
  cursor: move;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  border: 0.75px solid;
}
.analytics-panel .resizer.resizer-y {
  cursor: move;
  top: 0;
  left: 0;
  width: 0px;
  height: 100%;
  border: 0.75px solid;
}

.analytics-quadrants-panel {
  position: relative;
  height: inherit;
  z-index: 4;
}
.analytics-quadrants-panel.hidden {
  visibility: hidden;
}
.analytics-quadrants-panel .analytics-quadrant {
  position: relative;
  width: auto;
  height: auto;
  float: left;
}

.analytics-panel .docks__wrapper .dock__wrapper.react-draggable-dragging {
  z-index: 25;
}
.analytics-panel__wrapper {
  position: absolute;
  top: -9px;
  left: 0;
  width: 100%;
  z-index: 5;
  transition: width 0.3s ease;
  box-sizing: border-box;
  height: 100%;
}
.analytics-panel.dashboard-quadrant-panel {
  position: relative;
  z-index: 4;
  top: 0px;
  height: 100%;
}
.analytics-panel.dashboard-quadrant-panel.hidden {
  visibility: hidden;
}
.analytics-panel.dashboard-quadrant-panel .analytics-quadrant {
  position: relative;
  width: auto;
  height: auto;
  float: left;
}
.analytics-panel.dashboard-quadrant-panel .resizer {
  position: absolute;
  border-color: rgb(88, 95, 109);
  z-index: 10;
}
.analytics-panel.dashboard-quadrant-panel .resizer.resizer-x {
  cursor: move;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border: 0px;
}
.analytics-panel.dashboard-quadrant-panel .resizer.resizer-y {
  cursor: move;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  border: 0px;
}
.analytics-panel.dashboard-quadrant-panel .analytics-panel__settings {
  position: absolute;
  bottom: 38px;
  right: -31px;
  left: auto;
}
.analytics-panel.dashboard-quadrant-panel .analytics-panel__content {
  height: 100%;
  background: transparent;
  border: 0px;
}
.analytics-panel.dashboard-quadrant-panel .icons-panel {
  bottom: 0px;
  right: -33px;
}
.analytics-panel.dashboard-quadrant-panel .quadrants {
  display: block;
}

.dashboard-quadrant {
  position: relative;
  width: 50%;
  height: 50%;
  float: left;
  overflow: visible;
  padding-left: 2px;
  border-radius: 0;
  border: 0.75px solid;
  z-index: 0;
  border-color: var(--contrast-border-color);
}
.dashboard-quadrant > span {
  font-size: 30px;
  color: var(--contrast-border-color);
}
.dashboard-quadrant.isEmpty {
  text-align: center;
}
.dashboard-quadrant.isColliding.allowed {
  box-shadow: 0px 0px 22.5px 0px rgb(0, 173, 151);
}
.dashboard-quadrant.isColliding.allowed .dock__shadow {
  background-color: rgb(0, 173, 151);
  opacity: 0.15;
}
.dashboard-quadrant.isColliding.disallowed, .dashboard-quadrant.isColliding.removing {
  box-shadow: 0px 0px 22.5px 0px rgb(223, 0, 0);
}
.dashboard-quadrant.isColliding.disallowed .dock__shadow, .dashboard-quadrant.isColliding.removing .dock__shadow {
  background-color: rgb(223, 0, 0);
  opacity: 0.15;
}
.dashboard-quadrant .box {
  max-height: 100%;
  display: block;
}
.dashboard-quadrant .orderbook {
  position: relative;
}
.dashboard-quadrant .market__actions {
  position: fixed;
}
.dashboard-quadrant .market__actions ul {
  /* width: 0px; */
  float: right;
}
.dashboard-quadrant .icon-popover {
  position: absolute;
}
.dashboard-quadrant .icon-popover.left-border {
  right: 0px;
}

.chartbook {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.chartbook .m_chart {
  display: table-cell;
  width: 2%;
  overflow: hidden;
}

.m_chart__content__header {
  overflow: hidden;
}
.m_chart__content .periodChange, .m_chart__content .increaseToggle {
  padding-left: 0;
  padding-right: 7.5px;
}
.m_chart__content .periodChange :hover {
  background-color: var(--primary-color);
}
.m_chart__content .row.settings-bar {
  margin: 8px 4px;
}
.m_chart__content .row.settings-bar .btn {
  padding: 4px;
}
.m_chart__content .row.settings-bar .btn-group {
  margin-bottom: 2px;
}
.m_chart__content .charts .chart-component label {
  top: 50%;
  white-space: nowrap;
  position: relative;
  font-weight: bold;
  -webkit-transform: rotate(270deg);
  -webkit-transform-origin: right top;
  -moz-transform: rotate(270deg);
  -moz-transform-origin: right top;
  -ms-transform: rotate(270deg);
  -ms-transform-origin: right top;
  -o-transform: rotate(270deg);
  -o-transform-origin: right top;
  transform: rotate(270deg);
  transform-origin: center center;
  margin-left: -20px;
}
.m_chart__content .charts .chart-component .label-container {
  z-index: 1;
}
.m_chart__content .charts div {
  padding: 0px;
}

.priceSelection .form-check {
  padding-left: 7.5px;
}

.chart-no-data {
  width: 100%;
  display: table;
}
.chart-no-data h3 {
  display: table-cell;
  vertical-align: middle;
}

.modal-quoteRequestModal .react-datepicker-wrapper {
  display: block;
}
.modal-quoteRequestModal .react-datepicker {
  width: 328px;
}
.modal-quoteRequestModal .react-datepicker .react-datepicker__month-container .react-datepicker__header {
  width: 240px;
}
.modal-quoteRequestModal .quantity,
.modal-quoteRequestModal .price,
.modal-quoteRequestModal .buy {
  padding: 3.75px;
}

.acceptance-qr {
  position: absolute;
  width: 95%;
  background-color: rgb(255, 255, 255);
  border: 1.5px solid rgb(191, 195, 204);
  padding: 3.75px;
}
.acceptance-qr .btn.btn-accept {
  background-color: rgb(0, 173, 151);
  color: rgb(255, 255, 255);
}
.acceptance-qr .btn.btn-decline {
  background-color: rgb(248, 86, 86);
  color: rgb(255, 255, 255);
}

.request-popover {
  position: initial;
}

.sidebar__recent-actions .acceptance-qr .popover-title {
  display: none;
}

.format-info {
  text-align: right;
  color: rgb(191, 195, 204);
}

.priceAlarm__icons.triggered-true {
  color: red;
}
.priceAlarm__icons.triggered-false {
  color: green;
}

#priceAlarmForm .type-toggle .btn {
  border-width: 0px;
  margin-right: 2px;
  width: calc(50% - 2px);
}
#priceAlarmForm .react-datepicker {
  width: 328px;
}
#priceAlarmForm .gtc-button {
  cursor: pointer;
}

.btn.create-price-alarm {
  line-height: 0.55rem;
  margin-top: 2px;
}

.uitable.PriceAlarm {
  height: calc(100% - 25px);
}

.nav-link {
  cursor: pointer;
}

.sidebar .orders__table table {
  color: rgb(255, 255, 255) !important;
  font-size: 10.5px;
}
.sidebar .orders__table table thead th {
  font-weight: normal;
}
.sidebar .orders__table table thead th span.text-primary {
  color: rgb(255, 255, 255) !important;
}
.sidebar .orders__table table tbody tr {
  background-color: rgb(255, 255, 255);
}
.sidebar .orders__table table tbody tr:nth-of-type(odd) {
  background-color: rgb(243, 243, 243);
}

.contracts {
  position: relative;
  width: 100%;
}
.contracts__search input[type=text].form-control {
  background-color: rgb(255, 255, 255);
  border-radius: 3.75px;
  color: rgb(88, 95, 109);
}
.contracts__results {
  position: relative;
  display: none;
  width: 100%;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  border-radius: 3.75px;
}
.contracts__results.visible {
  display: block;
}
.contracts__results ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: rgb(88, 95, 109);
  height: auto;
  max-height: 150px;
  font-size: 12px;
}
.contracts__results ul li {
  cursor: pointer;
}
.contracts__results ul li:hover {
  background-color: rgba(88, 95, 109, 0.3);
}

#orderForm .modal-dialog {
  max-width: 560px;
}
#orderForm .modal-footer {
  border: none;
  padding: 0;
  padding-top: 15px;
  min-height: 37.5px;
  position: initial;
}
#orderForm .modal-content {
  width: 90%;
  height: initial;
}
#orderForm .modal-content .form-inline {
  margin-top: 11.25px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  border: none;
  justify-content: space-between;
}
#orderForm .modal-content .form-inline .form-group {
  padding-left: 0;
}
#orderForm .modal-content .form-inline .form-group label {
  padding-right: 7.5px;
}
#orderForm .modal-content label.qtyUnit {
  padding-left: 5px;
}
#orderForm .modal-content .form-group.restriction,
#orderForm .modal-content .form-group.price,
#orderForm .modal-content .form-group.quantity {
  padding: 3.75px;
  margin-left: 7.5px;
}
#orderForm .modal-content .form-group.restriction > input,
#orderForm .modal-content .form-group.price > input,
#orderForm .modal-content .form-group.quantity > input {
  text-align: right;
}
#orderForm .modal-content .form-group.restriction > select.form-control {
  padding: 7.5px;
  text-align: center;
}
#orderForm .modal-content .orderbook {
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
}
#orderForm .modal-content .orderbook .instrument {
  border: 0px;
}
#orderForm .modal-content .orderbook .instrument table thead tr:nth-child(1) th {
  top: 0px;
}
#orderForm .modal-content .orderbook p, #orderForm .modal-content .orderbook span, #orderForm .modal-content .orderbook label {
  font-size: 12px;
}

.sanitycheck-warnings li {
  margin-left: 12px;
}
.sanitycheck-warnings span {
  font-weight: bold;
  margin: 5px 0px 5px 0px;
  display: block;
}

.order-sidebar {
  position: absolute;
  top: 0px;
  right: -15px;
  height: 100%;
  overflow-y: auto;
  transition: all 0.3s ease;
  z-index: 6;
  display: block;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-font-color);
}
.order-sidebar.hidden {
  display: none;
}
.order-sidebar .threebar.cross {
  cursor: pointer;
  position: fixed;
  right: 370.5px;
  height: 30px;
  width: 37.5px;
  transition: left 0.3s ease;
  z-index: 4;
  background-color: var(--sidebar-bg);
  border-top-left-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
  color: var(--sidebar-font-color);
}
.order-sidebar .threebar.cross .bar {
  position: absolute;
  width: 22.5px;
  height: 3.75px;
  background: var(--sidebar-font-color);
  top: 12.75px;
  transition: all 0.3s ease;
  margin-left: 7.5px;
}
.order-sidebar .threebar.cross .bar:nth-child(1) {
  transform: rotate(45deg);
}
.order-sidebar .threebar.cross .bar:nth-child(2) {
  opacity: 0;
}
.order-sidebar .threebar.cross .bar:nth-child(3) {
  transform: rotate(-45deg);
}
.order-sidebar #orderForm {
  padding: 24px 16px 24px 12px;
}
.order-sidebar #orderForm h3 {
  font-size: 20px;
}
.order-sidebar #orderForm form {
  margin-top: 45px;
}
.order-sidebar #orderForm form h4 {
  font-size: 16px;
}
.order-sidebar #orderForm form .form-group label {
  width: 105px;
  font-size: 13.5px;
  font-weight: bold;
}
.order-sidebar #orderForm form .form-control {
  background-color: var(--dock-background);
  color: var(--default-font-color);
  display: inline-block;
  border-radius: 0%;
  border: none;
  height: 40px;
  padding: 8px;
}
.order-sidebar #orderForm form .form-control option {
  width: 160px;
  background-color: var(--dock-background);
  cursor: pointer;
}
.order-sidebar #orderForm form .form-control option:hover {
  background-color: var(--table-odd-background);
}
.order-sidebar #orderForm form .form-control .orderbook-preview .instrument table {
  background-color: var(--table-odd-background);
}
.order-sidebar #orderForm form input.form-control {
  width: 120px;
  text-align: right;
}
.order-sidebar #orderForm form select.form-control {
  width: 160px;
}
.order-sidebar #orderForm form .input-additional {
  color: var(--grey-font-color);
  background-color: var(--dock-background);
  display: inline-block;
  margin: 0px;
  text-align: center;
  min-width: 40px;
  padding: 10px 4px 10px 4px;
  height: 40px;
}
.order-sidebar #orderForm .number-spinner .spinner-vertical {
  display: none;
}
.order-sidebar #orderForm .instrument th label.entry span {
  font-size: 10px;
}
.order-sidebar #orderForm .instrument td label.entry span {
  font-size: 10px;
}
.order-sidebar #orderForm .orderbook-preview {
  margin-top: 50px;
}
.order-sidebar #orderForm .orderbook-preview .instrument table {
  background-color: var(--table-odd-background);
}
.order-sidebar #orderForm .orderbook-preview .instrument table tbody tr td.order.price label.entry {
  font-size: 12px;
}
.order-sidebar #orderForm .orderbook-preview .instrument table tbody tr td label.entry {
  font-size: 10px;
}
.order-sidebar #orderForm .orderbook-preview .instrument h4 {
  margin-bottom: 10px;
}
.order-sidebar #orderForm .form-group:nth-child(1) {
  margin-top: 50px;
}
.order-sidebar #orderForm .contract-selection {
  margin-bottom: 10px;
}
.order-sidebar #orderForm .contract-selection h4 {
  margin-bottom: 10px;
}
.order-sidebar .modal-footer {
  margin-top: 50px;
  justify-content: center;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-font-color);
}
.order-sidebar .modal-footer button {
  width: 132px;
  height: 40px;
  border-radius: 0%;
  font-size: 15px;
  color: var(--sidebar-font-color);
}
.order-sidebar .modal-footer button.btn-approval {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
}
.order-sidebar .modal-footer button.btn-secondary {
  color: var(--primary-font-color);
}
.order-sidebar .modal-footer button.btn-cancellation {
  background-color: transparent;
  border: 0.75px solid var(--sidebar-font-color);
  color: var(--sidebar-font-color);
}
.order-sidebar .modal-footer button.btn:focus {
  box-shadow: 0;
}

.bulk__icons {
  font-size: 12px;
  align-self: center;
}

.modal-bulk-order-upload .modal-dialog {
  max-width: 375px !important;
  max-height: 187.5px;
  align-self: center;
}

.modal-bulk thead {
  z-index: 1000;
}
.modal-bulk .oi {
  font-size: 15px;
  align-self: center;
}
.modal-bulk .modal-dialog {
  max-width: 70%;
}
.modal-bulk .modal-dialog .modal-body {
  height: 90%;
}
.modal-bulk .modal-dialog .modal-body .modal-footer {
  bottom: 0px;
  right: 0px;
  position: absolute;
}
.modal-bulk .modal-dialog .modal-content {
  height: 90%;
  position: absolute;
}
.modal-bulk .modal-dialog .modal-content .bulktable-full,
.modal-bulk .modal-dialog .modal-content .bulktable-half {
  height: 43%;
}
.modal-bulk .modal-dialog .modal-content .bulktable-full .scrollable,
.modal-bulk .modal-dialog .modal-content .bulktable-half .scrollable {
  height: 100%;
}
.modal-bulk .modal-dialog .modal-content .bulktable-errors {
  max-height: 43%;
  padding-bottom: 15px;
}
.modal-bulk .modal-dialog .dropzone {
  margin-right: 7.5px;
  width: 100%;
  border: 0.75px solid;
  text-align: center;
}
.modal-bulk .form-control {
  background-color: var(--table-even-background);
  color: var(--default-font-color);
}
.modal-bulk .number-spinner.input-group .form-control {
  width: 150px;
}
.modal-bulk .form-group label {
  width: 170px;
}
.modal-bulk .form-group .input-additional {
  display: inline-block;
  margin-left: 20px;
}
.modal-bulk #orderForm .modal-content .form-group.restriction {
  margin-left: 0px;
  padding: 0px;
}

.modal-meet-bulk__popover .modal-dialog {
  max-width: 550px;
  align-self: center;
}
.modal-meet-bulk__popover .btn-group-toggle label {
  background-color: rgb(191, 195, 204);
  color: rgb(255, 255, 255);
}
.modal-meet-bulk__popover .btn-group-toggle label.ask.active {
  background-color: rgb(241, 146, 122);
}
.modal-meet-bulk__popover .btn-group-toggle label.bid.active {
  background-color: rgb(170, 249, 132);
}
.modal-meet-bulk__popover .order-modify {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sidebar__bulk-views {
  height: 100%;
}

.instruments {
  position: relative;
  user-select: none;
  width: 100%;
}
.instruments__search input[type=text].form-control {
  background-color: var(--dock-background);
  border-radius: 4px;
  padding: 7.5px;
  font-size: 13.5px;
  height: auto;
}
.instruments__search input[type=text].active {
  color: var(--default-font-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.instruments__dropdown {
  position: absolute;
  display: none;
  font-size: 13.5px;
  background-color: var(--dock-background);
  border-bottom: var(--list-border);
  color: var(--default-font-color);
  width: 100%;
  z-index: 1;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.instruments__dropdown.visible {
  display: block;
}
.instruments__dropdown .type-trigger {
  position: relative;
  display: flex !important;
  background-color: transparent;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-top: 7.5px;
}
.instruments__dropdown .type-trigger .form-group {
  margin-bottom: 0.1875rem;
}
.instruments__dropdown .type-trigger .form-group.form-check:first-child {
  padding: 0;
  margin-left: 0.5625rem;
}
.instruments__dropdown .type-trigger .form-group.form-check:first-child .form-check-input {
  margin-left: 0.375rem;
}
.instruments__dropdown .type-trigger .form-group.form-check:nth-child(2) {
  padding: 0;
  margin-left: 1.875rem;
}
.instruments__dropdown .type-trigger .form-group.form-check:nth-child(2) .form-check-input {
  margin-left: 0.375rem;
}
.instruments__results {
  position: relative;
  overflow-y: auto;
  background-color: transparent;
}
.instruments__results ul {
  padding: 0;
  margin: 0;
  list-style: none;
  height: auto;
  max-height: 150px;
  font-size: 13.5px;
  color: var(--default-font-color);
}
.instruments__results ul li {
  cursor: move;
  padding: 7.5px;
  border-bottom: var(--list-border);
}
.instruments__results ul li:hover {
  background-color: var(--hover-color);
}
.instruments__results ul li.blocked .oi {
  color: var(--disabled-color);
}
.instruments__results ul .blocked {
  cursor: not-allowed;
}

.logger .modal.fade {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.logger .modal-dialog .modal-body {
  max-height: 262.5px;
  overflow-y: auto;
}
.logger .modal-dialog .modal-body table {
  width: 100%;
}
.logger .modal-dialog .modal-body table .pull-right {
  text-align: right;
  padding-right: 7.5px;
}
.logger .modal-dialog .modal-body table td {
  padding-left: 7.5px;
}
.logger .modal-dialog .modal-body table th {
  padding-left: 0px;
}
.logger .modal-dialog .modal-body table tbody > tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}

html,
body {
  font-family: var(--client-font-name);
  height: 100%;
  overflow: hidden;
  background-color: rgb(39, 38, 38);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--app-background);
}

#root {
  height: 100%;
}

div.theme-dark {
  background-color: rgb(39, 38, 38);
  color: rgb(255, 255, 255);
}

div.theme-light {
  background-color: rgb(236, 236, 236);
  color: rgb(53, 60, 73);
}

.logo {
  position: absolute;
  pointer-events: none;
}

.pull-right {
  float: right;
  padding-right: 7.5px;
}

.pull-left {
  float: left;
  padding-right: 7.5px;
}

