$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$secondary-font-color: var(--secondary-font-color);
$font-path: var(--client-font-path);
$font-name: var(--client-font-name);
$approval-color: var(--approval-color);
$cancellation-color: var(--cancellation-color);
$approval-font: var(--approval-font-color);
$cancellation-font: var(--cancellation-font-color);

.theme-dark {
  --app-background: rgb(39, 38, 38);  // 262525 Background of app
  --dock-background: rgba(51, 51, 51, 1); //333333
  --default-font-color: rgba(255, 255, 255, 1);
  --grey-font-color: rgba(118, 118, 118); //#767676
  --table-odd-background: rgba(60, 60, 60, 1); //3c3c3c
  --table-even-background: rgba(79, 79, 79, 1); //4f4f4f
  --table-odd-lighter-bg: rgb(66, 66, 66);
  --table-even-lighter-bg: rgb(83, 83, 83);
  --disabled-color: rgb(118, 118, 118, 1);
  --hover-color: var(--primary-color);
  --active-color: var(--primary-color);
  --list-border: 0.75px solid rgb(79, 79, 79, 1);
  --expiry-table-bg: rgba(0, 0, 0, 1);
  --marketsheet-headers: var(--primary-color);
  --dock-border: none;
  --sidebar-bg: var(--secondary-color);
  --sidebar-font-color:  var(--secondary-font-color);
  --border-color: rgb(79, 79, 79, 1);
  --contrast-border-color: rgba(79, 79, 79, 1);
  --primary-font-color: var(--default-font-color);
  --hover-modifier: var(--hover-modifier);
} 
.theme-light {
  --app-background: rgba(88, 95, 109, 0.3);
  --dock-background:rgba(255, 255, 255, 1);
  --default-font-color: rgba(53, 60, 73, 1);
  --grey-font-color: rgba(88, 95, 109, 1);
  --table-odd-background: rgba(255, 255, 255, 1);
  --table-even-background: rgba(232, 233, 237, 1);
  --table-odd-lighter-bg: rgba(255, 255, 255, 1);
  --table-even-lighter-bg: rgb(237, 238, 241);
  --disabled-color: rgb(156, 162, 172);
  --hover-color: var(--primary-color);
  --active-color: var(--primary-color);
  --list-border: 0.75px solid rgba(191, 195, 204, 1);
  --expiry-table-bg: rgba(232, 233, 237, 1);
  --marketsheet-headers: var(--primary-color);
  --dock-border: 0.75px solid rgba(232, 233, 237, 1);
  --sidebar-bg: var(--secondary-color);
  --sidebar-font-color:  var(--secondary-font-color);
  --border-color: rgba(191, 195, 204, 1);
  --contrast-border-color: rgb(146, 146, 146);
  --primary-font-color: var(--default-font-color);
  --hover-modifier: brightness(90%);
}


@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/pickr-widget/dist/pickr.min';
@import 'variables';
@import 'fonts';
@import 'typography'; 
@import 'inputs';
@import 'buttons';
@import 'components';
@import 'scrollbars';
@import '../js/shared/styles/shared';
@import 'oi-icons-bootstrap';
@import './themify';
@import '../js/authentication/styles/login';
@import '../js/dashboard/styles/dashboard';
@import '../js/dashboard/styles/profile';
@import '../js/dashboard/styles/contextMenu';
@import '../js/analyticsPanel/styles/analyticsPanel';
@import '../js/analyticsPanel/styles/analyticsQuadrant';
@import '../js/dashboard/styles/quadrants';
@import '../js/shared/ui/components/chart/styles/ui';
@import '../js/requests/styles/requests';
@import '../js/priceAlarm/styles/priceAlarms';
// Orders
@import '../js/orders/styles/ordersWrapper';
@import '../js/orders/styles/ordersTable';
@import '../js/orders/styles/orderForm';
@import '../js/bulkOrders/styles/bulkOrders';

// Instruments 
@import '../js/instruments/styles/instruments';

// Logger window
@import '../js/shared/logger/styles/logger';

html,
body {
  font-family: $font-name;
  height: 100%;
  overflow: hidden;
  background-color: $grey-dark;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--app-background);
}


#root {
  height: 100%;
}

div.theme-dark {
  background-color: rgba(39, 38, 38, 1);
  color: $white;
}
div.theme-light {
  background-color: rgb(236, 236, 236, 1);
  color: $grey-charcoal; 
}

.logo {
  position: absolute;
  pointer-events: none;
}

.pull-right {
  float: right;
  padding-right: $padding-default;
}

.pull-left {
  float: left;
  padding-right: $padding-default;
}