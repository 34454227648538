.logger {
    .modal.fade {
        opacity: 1;
        background-color: $black-opacity;
    }

    .modal-dialog {
        .modal-body {
            max-height: 262.5px;
            overflow-y: auto;
            table {
                width: 100%;

                .pull-right {
                    text-align: right;
                    padding-right: 7.5px;
                }

                td {
                    padding-left: 7.5px;
                }

                th {
                    padding-left: 0px;
                }

                tbody>tr:nth-child(odd) {
                    background-color: $white;
                }

            }
        }
    }
}