.chartbook {
    display: table;
    table-layout: fixed;
    width: 100%;

    .m_chart {
        display: table-cell;
        width: 2%;
        overflow: hidden;
    }
}

.m_chart {
    &__content {
        &__header {
            overflow: hidden;
        }
    
        .periodChange, .increaseToggle {
            padding-left: 0;
            padding-right: $padding-default;
        }
        .periodChange {
            :hover {
                background-color: $primary-color;
            }
        }
        .row.settings-bar {
            margin: 8px 4px;

            .btn {
            padding: 4px;
            }
            .btn-group {
            margin-bottom: 2px;
            }
        }
        .charts {
            .chart-component{
                label {
                    top: 50%;
                    white-space: nowrap;
                    position: relative;
                    font-weight: bold;
                    
                    -webkit-transform: rotate(270deg);
                    -webkit-transform-origin: right top;
                    -moz-transform: rotate(270deg);
                    -moz-transform-origin: right top;
                    -ms-transform: rotate(270deg);
                    -ms-transform-origin: right top;
                    -o-transform: rotate(270deg);
                    -o-transform-origin: right top;
                    transform: rotate(270deg);
                    transform-origin: center center;
                    margin-left: -20px;
                }

                .label-container {
                    z-index: 1;
                }
            } 
            div {
                padding: 0px;
            }
        }
    }
}

.priceSelection{
    .form-check{
        padding-left: $padding-default;
    }
} 

.chart-no-data{
    width: 100%;
    display: table;
    h3{
        display: table-cell;
        vertical-align: middle;
    }
}