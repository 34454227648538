.modal-quoteRequestModal {
    .react-datepicker-wrapper {
        display: block;
    }
    .react-datepicker {
        width: 328px;

        .react-datepicker__month-container {
            .react-datepicker__header {
               width: 240px
            }
        }
    }

    .quantity,
    .price,
    .buy {
        padding: calc($padding-default/2);
    }
}

.acceptance-qr {
    position: absolute;
    width: 95%;
    background-color: $white;
    border: 1.5px solid $grey-cloud;
    padding: calc($padding-default / 2);

    .btn.btn-accept {
        background-color: $instrument-green;
        color: $white;

    }

    
    .btn.btn-decline {
        background-color: $instrument-red;
        color: $white;
    }
}

.request-popover {
    position: initial;
}

.sidebar__recent-actions .acceptance-qr .popover-title{
    display: none;
}

.format-info {
    text-align: right;
    color: $grey-cloud;
}