.meet-notifications {
    position: fixed;
    right:  $right-position;
    bottom: $bottom-position;
    z-index: 999;


    .meet-notification {
        padding: $padding-notification;
        border: none;
        animation: 0.3s ease-out 0s 1 slideInFromRight;
        cursor: pointer;

        &.confirmed {
            animation: none;
            cursor: default;
        }

        &.alert-info {
            color: $white;
            background-color: $instrument-green;
        }

        &.alert-warning {
            color: $white;
            background-color: $instrument-orange;
        }

        .notification-confirmation {
            display: block;
            margin-left: 20px;
            float: right;
            margin-top: -3px;
            padding: $padding-notification-button;
            border: 0.75px solid $white;
            border-radius: $border-radius;
            cursor: pointer;
        }
    }

    &__modal {
        .modal.fade {
            opacity: 1;
            background-color: $grey-opacity;
        }

        .modal-dialog .modal-body {
            .form-control {
                display: inline-block;
                margin: auto $margin-default;;
                width: 2.25rem;
                height: inherit;
                padding: 0;
                line-height: 1;
                text-align: center;

                &.select-input-outline {
                    color: $grey-iron;
                    text-align-last: center;
                    font-weight: 300;
                    border: none;
                    border-radius: 0;
                    border-bottom: 0.75px solid $primary-color;
                    background: transparent;
                    width: 6.75rem;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 0.75px;
                    text-overflow: '';

                    &:focus {
                        outline: none !important;
                        box-shadow: inherit;
                    }
                }
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutFromRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}