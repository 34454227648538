.meet-settings, .meet-bulk {
    &__modal {
        .modal.fade {
            opacity: 1;
            background-color: $black-opacity;
        }

        .modal-dialog {
            .modal-body {
                .oi {
                    cursor: pointer;
                    margin-top: 6px;
                    color: var(--default-font-color);
                    &:hover {
                        color: var(--hover-color);
                    }
                }
                .nav-item, ul[role=tablist] {
                    li {
                        font-weight: bold;
                        margin: 0 0 0 0;
                    }
                    // display: table;
                    // table-layout: fixed;
                    border: none;
                }
            }

            input[type=color] {
                cursor: pointer;
                -webkit-appearance: none;
                border: 0.75px solid var(--default-font-color);
                &:focus {
                    outline: none;
                }
            }

            input[type=color]::-webkit-color-swatch-wrapper {
                padding: 0;
            }

            input[type=color]::-webkit-color-swatch {
                border: 0.75px solid $grey-iron;
            }

            input[type=text] {
                height: 40px;
                color: var(--default-font-color);
            }

            .pcr-app input { // exception for color picker
                color: $grey-charcoal;
            }

            .form-group {
                label {
                    height: 40px;
                }
            }

            .col-3 {
                padding-right: 18.75px;
            }

            .col-1 {
                padding-left: 0;
            }

            .nav-item, ul[role=tablist]{ 
                width: 90%;
                li {
                    width: 50%;
                }
            }
        }
    }
}

.meet-settings__modal {
    div.settings-scroll {
        max-height: 550px;
        min-height: 550px;
    }
}

div.settings-scroll {
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pickr {
    width: 100%;

    .pcr-button {
        width: 100%;
        border: 0.75px solid $black;
    }
}

.pcr-app.visible {
    margin-left: 0 !important;
    position: absolute;
    left: 30% !important;
    transform: translateY(-50%);
}

.config-tab{
        .oi-action-undo {
            margin-top: 0 !important;
        }
        .number, text {
            input {
                text-align: right;
                padding-right: $padding-default;
            }
        }
         
        .selection {
            input {
                padding: $padding-default;
                text-align: center;
            }
        }
}

.btn-group-toggle {

    label {
        &.btn {
            padding: 3,75px $padding-default;
            background-color: transparent;
            color: var(--default-font-color);

            &.option1.active {
                background-color: rgba(241,146,122, 1);
            }

            &.option2.active {
                background-color: rgba(170,249,132, 1);
            }
        }
    }
}