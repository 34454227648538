.analytics-panel {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    z-index: 5;
    transition: width 0.3s ease;
    box-sizing: border-box;

    &__content {
        position: relative;
        height: auto;
        overflow: hidden;
        border-bottom-right-radius: $border-radius;
        transition: height 0.1s linear;
        background-color: var(--dock-background);
        .info-panel {
            position: relative;
            width: 100%;
            height: 100%;

            .quadrants-preview {
                position: relative;
                width: 100%;
                height: 100%;
                padding: $padding-default;

                .quadrant {
                    position: relative;
                    width: 50%;
                    height: 50%;
                    float: left;
                    border: var(--list-border);
                    text-align: center;
                }
            }
        }

        .uitable {
            .search,
            .table-visibility-filter {
                position: fixed;
                top: 50;
                width: calc(100% - 20px);
            }

            &__actions {
                position: fixed;
                padding: 0;
            }
        }
    }

    .icons-panel {
        position: absolute;

        .drag-icon,
        .settings-icon {
            width: 33.75px;
            background-color: $secondary-color;
            padding: $padding-default;

            .oi {
                color: $white;
                font-size: $font-size-headline3;
            }

            &.drag-icon {
                cursor: ns-resize;
            }

            &.settings-icon {
                cursor: pointer;

                &.active {
                    border-bottom-right-radius: 0;
                    border-right-color: transparent;
                }
            }
        }

        .active-background {
            background-color: var(--active-color);
        }
    }

    .analytics-quadrants-panel {
        position: absolute;
    }

    &__settings {
        position: fixed;
        display: none;
        left: 84px;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        &.expanded {
            display: block;
        }

        .quadrants {

            .quadrant,
            .action-block {
                padding: 3.75px;
                cursor: pointer;
                background: $white;
                border: var(--list-border);
                background: var(--table-even-background);
                color: var(--default-font-color);

                &.active,
                &:hover {
                    background: var(--hover-color);
                }
            }

            .action-block {
                padding-left: $padding-default;
                padding-right: $padding-default;
            }
        }

        .panels {
            background: var(--table-even-background);
            color: var(--default-font-color);

            .panel {
                &-column {
                    width: 100%;
                    cursor: pointer;
                    padding: $padding-default;
                    border: var(--list-border);
                    min-width: 112.5px;

                    &:hover {
                        background: var(--hover-color);
                    }

                    &.exists {
                        cursor: no-drop;
                        background: var(--active-color);
                    }
                }
            }
        }
    }
    .resizer {
        position: absolute;
        border-color: var(--table-even-background);
        z-index: 5;
        &.resizer-x {
            cursor: move;
            top: 0;
            left: 0;
            width: 100%;
            height: 0px;
            border: 0.75px solid;
        }

        &.resizer-y {
            cursor: move;
            top: 0;
            left: 0;
            width: 0px;
            height: 100%;
            border: 0.75px solid;
        }
    }
}