.login-form {
  width: $login-width;
  min-height: $login-height;
  align-self: center;
  border-radius: $border-radius;
  padding: $padding-login;
  background-color: $white;

    .form-control {
      border-bottom: .75px solid $secondary-color;
      :focus {
        border-color: $secondary-color; 
    }
  }

  .login-error{
    color: $secondary-color;
  }
  .alert {
    z-index: 1;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo {
    position: relative;
    text-align: center;
    width: 100%;
    .login-title {
      width: 100%;
      text-align: center;
    }
    img {
      max-width: $login-width - 35px;
      max-height: $login-height;
    }
  }
}
.login-box {
  width: $login-width;
  align-self: center;
  border-radius: $border-radius;
  padding: $padding-login;
  background-color: $white;
  text-align: center;
}
.spinner-login {
  margin-top: 40px;
  text-align:  center;
}