input {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    caret-color: $primary-color;
}

// Default inputs
input[type=text],
input[type=password],
input[type=number] {
    border: none;
    border-radius: 0;
    border-bottom: 0.75px solid $primary-color;
    color: $grey-charcoal;
    font-size: $font-size-normal;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

/*
* Range input
*/

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 2.625px 0;
    background-color: transparent;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2.25px;
    cursor: pointer;
    box-shadow: 0px 0px 0px $black, 0px 0px 0px $full-opacity;
    background: $primary-color;
    color: $secondary-color;
    border-radius: 0px;
    border: 0px solid $full-opacity;
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px $black, 0px 0px 0px $full-opacity;
    border: 0px solid $full-opacity;
    height: 7.5px;
    width: 7.5px;
    border-radius: 37.5px;
    background: $primary-color;
    color: $secondary-color;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2.625px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: $primary-color;
    color: $secondary-color;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 2.25px;
    cursor: pointer;
    box-shadow: 0px 0px 0px $full-opacity, 0px 0px 0px $full-opacity;
    background: $primary-color;
    color: $secondary-color;
    border-radius: 0px;
    border: 0px solid $full-opacity;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px $full-opacity, 0px 0px 0px $full-opacity;
    border: 0px solid $full-opacity;
    height: 7.5px;
    width: 7.5px;
    border-radius: 37.5px;
    background: $primary-color;
    color: $secondary-color;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 2.25px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: $grey-charcoal;
    border: 0px solid $full-opacity;
    border-radius: 0px;
    box-shadow: 0px 0px 0px $full-opacity, 0px 0px 0px $full-opacity;
}

input[type=range]::-ms-fill-upper {
    background: $primary-color;
    color: $secondary-color;
    border: 0px solid $full-opacity;
    border-radius: 0px;
    box-shadow: 0px 0px 0px $full-opacity, 0px 0px 0px $full-opacity;
}

input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px $full-opacity, 0px 0px 0px $full-opacity;
    border: 0px solid $full-opacity;
    height: 7.5px;
    width: 7.5px;
    border-radius: 37.5px;
    background: $primary-color;
    color: $secondary-color;
    cursor: pointer;
    height: 2.25px;
}

input[type=range]:focus::-ms-fill-lower {
    background: $primary-color;
    color: $secondary-color;
}

input[type=range]:focus::-ms-fill-upper {
    background: $primary-color;
    color: $secondary-color;
}


/*
* Checkbox & radio inputs
**/

$radio-height: $font-size-normal;
$radio-width: $font-size-normal;

$checkbox-height: $font-size-normal;
$checkbox-width: $font-size-normal;

input[type=radio]:not(:checked),
input[type=radio]:checked,
input[type=checkbox]:not(:checked),
input[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
}

input[type=radio]:not(:checked).horizontal,
input[type=radio]:checked.horizontal,
input[type=checkbox]:not(:checked).horizontal,
input[type=checkbox]:checked.horizontal {
    position: absolute;
    top: -9999px;
    left: auto;
}

input[type=radio]:not(:checked)+label,
input[type=radio]:checked+label {
    position: relative;
    overflow: hidden;
    padding-left: 18.75px;
    cursor: pointer;
    display: inline-block;
    height: 15px;
    line-height: 15px;
}

input[type=checkbox]:not(:checked)+label,
input[type=checkbox]:checked+label {
    position: relative;
    overflow: hidden;
    padding-left: 18.75px;
    cursor: pointer;
    display: inline-block;
    height: 15px;
    font-size: $font-size-normal;
    line-height: 16.5px;
}

input[type=radio]+label:after,
input[type=checkbox]+label:before,
input[type=checkbox]+label:after {
    content: '';
    position: absolute;
    left: 0;
    z-index: 1;
}

input[type=checkbox]:not(:checked)+label:before {
    top: 0px;
    width: $checkbox-width;
    height: $checkbox-height;
}

input[type=checkbox]:not(:checked)+label:after {
    top: 1.5px;
    width: $checkbox-width;
    height: $checkbox-height;
    border: 1.5px solid $grey-iron;
    z-index: 0;
    border-radius: $border-radius - 2.25px;
}

input[type=checkbox]:checked+label:before {
    top: 1.5px;
    width: 6px;
    height: 12px;
    border-top: 0.75px solid transparent;
    border-left: 0.75px solid transparent;
    border-right: 1.5px solid $secondary-color;
    border-bottom: 1.5px solid $secondary-color;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
}

input[type=checkbox]:checked+label:after {
    top: 1.5px;
    width: $checkbox-width;
    height: $checkbox-height;
    border: 1.5px solid $primary-color;
    background-color: $primary-color;
    color: $secondary-color;
    border-radius: $border-radius - 2.25px;
    z-index: 0;
}

input[type=radio]:not(:checked)+label:after {
    top: 0px;
    width: $radio-width;
    height: $radio-height;
    border: 1.5px solid $grey-iron;
    z-index: 0;
    border-radius: 50%;
}

input[type=radio]:checked+label:after {
    top: 0.75px;
    left: 1.5px;
    width: $radio-width - 0.75px;
    height: $radio-height - 0.75px;
    border: 1.5px solid $white;
    box-shadow: 0 0 0 0.75px $primary-color;
    background-color: $primary-color;
    color: $secondary-color;
    z-index: 0;
    border-radius: 50%;
}

/* disabled radio */
input[type=radio]:disabled:not(:checked)+label:after {
    border: 1.5px solid $grey-cloud;
}

input[type=radio]:disabled:checked+label:after {
    background-color: $grey-cloud;
}

/* disabled checkbox */
input[type=checkbox]:disabled:not(:checked)+label:before,
input[type=checkbox]:disabled:checked+label:before {
    top: 1.5px;
    box-shadow: none;
    background-color: $white;
    width: $checkbox-width;
    height: $checkbox-height;
    border: 1.5px solid $grey-cloud;
}

input[type=radio]:disabled+label,
input[type=checkbox]:disabled+label {
    color: $grey-cloud;
}

input[type=radio]:disabled:not(:checked)+label:hover:after,
input[type=checkbox]:disabled:not(:checked)+label:hover:before {
    cursor: not-allowed;
}

// Select input
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    font-family: 'DINOT';
    color: $grey-charcoal; 
    font-size: $font-size-normal;
    padding-left: $padding-default;
    padding-right: $padding-default;
    margin: 0;
    cursor: pointer;
}

.number-spinner {
    white-space:nowrap;

    .spinner-vertical {
        position: relative;
        white-space: nowrap;
        width: 1%;
        vertical-align: middle;
        display: inline-block;
    
        a {
            display: block;
            float: none;
            width: 100%;
            max-width: 100%;
            padding: 8px;
            margin-left: -1px;
            position: relative;
            border-radius: 0;
            text-align: center;
            border: 0px;
            background-color: transparent;
            color: $primary-color;
            outline: none;
        }

        a:last-child {
            margin-top: -2px;
        }
        span {
            position: absolute;
            top: 0;
            left: 4px;
            text-align: center;

            &.oi {
                color: $primary-color;
            }
        }
    }

    &.input-group {
        display: inline-block;
        .form-control {
            flex: initial;
            width: auto;
        }
        width: initial;
    }

    input {
        display: inline-block;
    }
}

input[type=text],
input[type=password],
input[type=number] {
    background: transparent;
    color: var(--default-font-color);
}
.login-form {
    input[type=text], input[type=password]  {
        color: $grey-charcoal;
    }
}

