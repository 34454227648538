.sidebar {
    .orders {
        &__table {
            table {
                color: $white !important;
                font-size: 10.5px;

                thead th {
                    font-weight: normal;

                    span.text-primary {
                        color: $white !important;
                    }
                }

                tbody tr {
                    background-color: $table-even-color;
                    &:nth-of-type(odd) {
                        background-color: $table-odd-color;
                    }
                }
            }
        }
    }
}