.modal.meet-modal {
    opacity: 1;
    display: block;

    .modal-dialog {
        // Bugfix - without these, if the modal's content is larger than screen and scrolling is enabled, clicking after scrolling would move the window.
        position: fixed;
        top: 0;

        display: inline-block;
        margin: 0;
        width: 100%; // Max-width depends on screen size. */
        height: 100%;

        .modal-header {
            padding: 0;
            border: none;
            color: var(--default-font-color);
            &__actions {
                button {
                    cursor: pointer;
                    border: none;
                    background-color: transparent;

                    &:focus,
                    &:active {
                        outline: none;
                    }
                    .oi {
                        color: var(--default-font-color) !important;
                    }
                }
            }
        }

        .modal-body {
            padding: 0;
            padding-top: $padding-modal;
            color: var(--default-font-color);
        }

        // Text-align used to horizontally center modal should be ignored in its content.
        .modal-content {
            padding: $padding-modal;
            text-align: initial;
            background-color: var(--dock-background);
        }

        .modal-footer {
            border: none;
            padding: 0;
            padding-top: $padding-modal;
            min-height: $footer-height;
        }

        .modal-close {
            position: absolute;
            bottom: $padding-modal;
            left: $padding-modal;
        }
    }

    // Forbid positioning of a modal that is insinde of another modal.
    .modal.meet-modal .modal-header__actions .change-position {
        display: none;
    }

    /** Position of the modal window on the screen. */

    &:not(.position-left):not(.position-right) {
        display: flex;
        justify-content: center;
    }

    &.position-right .modal-dialog {
        right: 0;
    }

    &:not(.position-top):not(.position-bottom) .modal-dialog {
        display: flex;
        align-items: center;
    }

    &.position-bottom .modal-content {
        position: absolute;
        bottom: 0;
    }
}

.position-picker {
    text-align: center;
    color: var(--default-font-color);
    background: var(--dock-background);

    .modal-header {
        padding: 0;
        button {
            cursor: pointer;
            border: none;
            background-color: transparent;

            &:focus,
            &:active {
                outline: none;
            }
        }

        border: none;
    }

    .modal-body {
        padding: 0;
        padding-top: $padding-modal;
        button {
            width: 30px;
            height: 30px;
            padding: 0; // Fix for Firefox
            font-size: 0; // Vertically aligns icon in button
            background: var(--dock-background);
            .rotate-45 {
                transform: rotate(45deg);
            }
        }
    }

    // Sidebar uses white "oi" icons. When modal window's code is placed inside the sidebar, color needs overriding.
    .oi:not(.active) {
        color: $grey-charcoal;
    }
}