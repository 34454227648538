.meet-table {
    height: 100%;
    .table-responsive {
        height: 100%;
        overflow-y: hidden;
    }
    .table {
        table-layout: fixed;
        margin: 0;
        min-width: 100%;
        border-collapse: collapse;
        overflow-y: hidden;
        height: 100%;

        &-header {
            &-title {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &-cell {
                &-wrapper {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: -3.75px;
                    padding: 3.75px;
                    flex: 1 1;
                    cursor: pointer;
                }
            }
        }

        thead {
            background-color: var(--marketsheet-headers);
            position: sticky;
            width: 100%;
            display: table;
            table-layout: fixed;

            tr th {
                text-align: center;
                padding: 4px 4px;
                height: 28px;
                color: var(--default-font-color);
                font-size: $font-size-small;
                &:first-of-type {
                    text-align: left;
                }
                .table-header-title {
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }

        tbody.emptyBody{
            width: 100%;
            display: table;
            table-layout: fixed;
        }

        tbody {
            tr td {
                text-align: center;

                &:first-of-type,
                &.contract {
                    text-align: left;
                }
            }
        }
    }

    .user-select-none {
        user-select: none;

        :focus {
            outline: none;
        }
    }
}

// Invidual colummns styling 
.sidebar__recent-actions .meet-table {
//orders: contract, b/s, limit, rem.qty
//alltrades: contract, prc, qty
//owntrades: contract, buy, prc, qty

    .table.Order, .table.Owntrade, .table.Request {
        .contract{
        width: 55%; //90px
        }
    }

    .table.Order, .table.Trade, .table.Owntrade, .table.Request, .table.Log {
        .state {
            width: 15%
        }

        .level {
            width: 19%;
        }

        .price,
        .quantity,
        .limit,
        .remainingQuantity {
            width: 16%; //75px;
        }

        .paidGiven,
        .buySell,
        .buy {
        width: 13%; //50px
        }

        .timestamp {
            width: 19%;
        }

        .time {
            width: 21%;
        }
    }
    
    .table.Trade {
        .contract {
            min-width: 68%;
        }

        .price,
        .quantity {
            width: 16%;
        }
    }
}

// Invidual colummns styling 
.meet-table  {
    //orders: contract, b/s, limit, rem.qty, ord.qty, user, date, time, restriction
    //alltrades: contract, paid/given, price, qty, aggressor, initiator, date, time
    //owntrades: contract, b/s, prc, qty, aggressor, initiator, date, time

    .table.Log {
        .level {
            width: 10%;
        }
        .time {
            width: 9%;
        }
    }
        
    .table.Trade {
        .contract {
            width: 41%;
        }

        .price,
        .quantity {
            width: 9%;
        }
        .paidGiven {
            width: 5%;
        }
        
        .qtyUnit {
            width: 6%;
        }

        .initiator,
        .aggressor {
            width: 6%
        }
    
        .timestamp,
        .time,
        .date {
            width: 9%;
        }
    }

    .table.Owntrade {
        .contract {
            width: 31%;
        }

        .price,
        .quantity {
            width: 8%;
        }
        .buy
        {
            width: 5%;
        }
        .qtyUnit {
            width: 5%;
        }

        .initiator,
        .aggressor {
            width: 6%
        }
    
        .timestamp,
        .time,
        .date {
            width: 9%;
        }
    }

    .table.Order {
        .id {
            width: 5%;
        }
        .contract {
            width: 35%;
        }

        .limit,
        .remainingQuantity,
        .orderedQuantity {
            width: 7%;
        }
        
        .buySell {
            width: 5%;
        }

        .userId {
            width: 5%
        }
    
        .timestamp,
        .time,
        .date {
            width: 8%;
        }
        .restriction {
            width: 5%;
        }
        .qtyUnit {
            width: 5%;
        }
    }

    .table.Request {
        .id {
            min-width: 5%
        }
        .contract {
            min-width: 20%;
        }

        .price,
        .quantity {
            min-width: 5%;
        }
        
        .buy {
            width: 5%;
        }

        .requester,
        .answerer {
            min-width: 10%
        }
    
        .validFrom,
        .validTo  {
            min-width: 10%;
        }

        .meetState,
        .comment,
        .reference,
        .requesterBusinessUnitId,
        .requesterPortfolioId,
        .traderBusinessUnitId,
        .traderPortfolioId {
            min-width: 5%
        }
    }

    .table.PriceAlarm {
        .contract {
            min-width: 35%;
        }
    }
/**
<col class="traderPortfolioId">
*/
        .table.BulkError {
            .bulkRow{
                width: 10%;
            }
            .error{
                width: 90%;
            }
        }

        .table.BulkOrder {
            .date{
                width: 10%;
            }
            .timeShort{
                width: 10%;
            }
            .periodShort{
                width: 8%;
            }
            .grid{
                width: 18%;
            }
            .buySell{
                width: 8%;
            }
            .price{
                width: 10%;
            }
            .quantity{
                width: 10%;
            }
            .quantityUnit{
                width: 10%;
            }
            .delete{
                width: 8%;
            }
            .status{
                width: 8%;
            }
        }
    }


.time,
.level {
    width: 60px
}

.date {
    width: 75px;
}

.message {
    text-align: left !important;
}

#meet-tooltip {
    width: 100%;
    .popover-content table {
        width: 100%;
    }
    .meet-tooltip-name{
         padding-right: $padding-default;
    }
    .meet-tooltip-value.contract {
        font-weight: bold;
        display: block;
    }
}
.trade-db-controls {
    table.Trade, table.Owntrade {
        height: calc(100% - 22px);
    }
    height: 100%;
}
ul.trade-limit-actions {
    li {
        float: left;
        padding: 8px 25px;
        cursor: pointer;
        height: 30px;
        font-weight: 500;
        &.active {
            color: $primary-color;
        };
    }
    li.trade-limit-setting {
        float: right;
        padding: 8px 5px;
    }
    list-style: none;
    padding: 0px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0px;
    font-size: $font-size-small;
}
.sidebar__recent-actions {
    ul.trade-limit-actions {
        li {
            color: var(--grey-font-color);
            padding: 8px 10px;
            height: 23px;
            &.active {
                color: var(--primary-color);
            };
        }
    }
    .meet-table {
        thead, thead th {
            background: transparent;
        }
    }
}