h1,
h2,
h3,
h4 {
    margin-bottom: 0;
}

h1 {
    font-size: $font-size-headline1;
    font-weight: normal;
}

h2 {
    font-size: $font-size-headline2;
    font-weight: normal;
}

h3 {
    font-size: $font-size-headline3;
    font-weight: bold;
}

h4 {
    font-size: $font-size-headline4;
    font-weight: bold;
}
body {
    font-size: $font-size-normal;
}
p,
span,
label,
div{
    font-size: 100%;
}
.oi {
    font-size: $font-size-headline3;
}

.small-text {
    font-size: $font-size-small;
}