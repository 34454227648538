.sidebar {
  position: absolute;
  top: 0px;
  right: -15px;
  width: 300px;
  height: 100%;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-font-color);
  overflow-y: auto;
  transition: all 0.3s ease;

  z-index: 5;


  &.react-draggable-transparent-selection {
    background-color: $grey-opacity;
    z-index: 0;
  }

  .innerSidebar {
    overflow-y: auto;
    overflow-x: hidden;
    padding: $padding-sidemenu;
    min-height: 100%;
    position: relative;
    padding-bottom: 130px;
    .pcr-app {
      font-family: $font-name;
    }
  }

  &__header {

    .oi {
      cursor: pointer;
      font-size: $font-size-headline3;

      &.oi-ellipses {
        transform: rotate(90deg);
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
  }


  &__topic {
    padding: $padding-sidemenu-topic;

    .subtitle {
      padding-bottom: $padding-tile;
    }

    .sidebar__recent-actions {
      color: var(--default-font-color);
    }
    &.logout {
      float: right;

      .logout-text, a {
        cursor: pointer;
        margin-right: 10px;
        span {
          color: var(--sidebar-font-color);
          font-weight: 500;
        }

      }
      a:hover {
        text-decoration: underline;
        color: var(--sidebar-font-color);
      }
      a.oi:hover {
        text-decoration: none;
      }
      span.countdown {
        margin-right: 10px;
      }
    }
  }


  &__footer {
    width: 100%;
    position: absolute;
    bottom: 0px;
    a {
      cursor: pointer;
    }

    .logo {
      position: relative;
      width: 187px;
      height: fit-content;
    }
  }

  .threebar {
    cursor: pointer;
    position: fixed;
    right: 337.5px;
    height: 30px;
    width: 37.5px;
    transition: left 0.3s ease;
    z-index: 4;
    background-color: var(--sidebar-bg);
    border-top-left-radius: 7.5px;
    border-bottom-left-radius: 7.5px;

    .bar {
      position: absolute;
      width: 22.5px;
      height: 3.75px;
      background: var(--sidebar-font-color);
      top: 12.75px;
      transition: all 0.3s ease;
      margin-left: 7.5px;

      &:nth-child(1) {
        transform: translateY(-150%);
      }

      &:nth-child(3) {
        transform: translateY(150%);
      }
    }

    &.cross {
      .bar {
        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.hidden {
    transform: translateX(100%);
    overflow-y: inherit;

    & ~ .analytics-panel  {
      width: calc(100% - 37.5px);
    }
  }

  & ~ .analytics-panel  {
    width: calc(100% - 367.5px);
  }


  &__recent-actions {
    position: relative;
    height: $recent-action-height;
    border-radius: $border-radius;
    padding: $padding-tile;
    user-select: none;
    padding-top: 0;
    background-color: var(--table-odd-background);
    .tabs {
      .nav-tabs {
        display: flex;

        .nav-item {
          
          margin-bottom: 0px;
          &.active {
            order: 6;
          }
          
          .nav-link {
            padding: $tabs-link-padding;
          }
        }
        &.nav-tabs-5 {
          .nav-item.active {
              order: 5;
              width: 66%;
          }
        }
      }
      .tab-content {
        height: calc(100% - 75px);
      }

      // thead {
      //   position: absolute !important;
      // }
    }

    .dragger {
      position: relative;
      width: 100%;
      height: $dragger-height;
      cursor: move;

      &:hover {
        background-color: $dragger-color;
      }
    }
  }

  &__profile-views,
  &__favorite-views,
  &__bulk-views {

    .profile-views__icons {
      span {
        cursor: pointer;
        padding-left: 11.25px;
      }
    }

    &__list {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          color: var(--default-font-color);
          display: block;
          margin: $margin-default 0;
          padding: $padding-default;
          border-radius: $border-radius;
          cursor: pointer;

          label {
            cursor: pointer;
            margin: 0;
            padding-left: $padding-default;
          }

          &.more {
            padding: 0;
          }
        }

        li.more {
          background-color: transparent;
          text-align: left;
          color: inherit;
        }
      }
    }

    &__borderless-list {
      table {

        thead th {
          border: none;
        }

        tbody tr {
          td {
            padding: $padding-default;
            vertical-align: middle;
          }
        }
      }
    }

    .blocked {
      cursor: not-allowed !important;

      label {
        cursor: not-allowed !important;
      }
    }
  }

  &__favorite-views {
    &__list {
      ul li:not(.more) {
        cursor: move;

        label {
          cursor: move;
        }
      }
    }
  }

  &-context-menu {
    position: absolute;
    border-radius: 5.25px;
    right: 22.5px;
    width: 135px;
    z-index: 3;
    background: var(--table-odd-background);
    border: var(--list-border);
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: $padding-default;
        color: var(--default-font-color);
        border-bottom: var(--list-border);
        cursor: pointer;

        &:hover {
          background-color: var(--hover-color);
        }
      }
    }

    &-button:after {
      cursor: pointer;
      content: '\2807';
      font-size: $font-size-normal;
      margin-right: -7.5px;
    }
  }
}

.sidebar__profile-views, .sidebar__favorite-views {
  table {
    color: var(--default-font-color);
  }
  &__borderless-list, &__list {
    thead {
      display: block;
    }

    tbody {
      display: block;
      max-height: $profile-views-max-table-height;
      overflow-y: auto;
    }

    tbody tr td:first-child, thead th:first-child {
      width: 300px;
      max-width: 300px;
      overflow-wrap: break-word;
    }

    tbody tr td:nth-child(2), thead th:nth-child(2) {
      width: 90px;
    }

    tbody tr td:nth-child(3), thead th:nth-child(3) {
      width: 90px;
    }

    .oi-trash {
      cursor: pointer;
    }

    .select-item, .dragger-item, .blocked {
      padding: $padding-default;
      border-radius: $border-radius;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      border: none;
      background-color: var(--dock-background);
      color: var(--default-font-color);
      .left-container {
        display: flex;
        width: 95%;
      }

      .dashboard-name, .favorite-name {
        padding-left: $padding-default;
        padding-right: $padding-default;
        overflow-x: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
        
      label {
        cursor: pointer;
        overflow-x: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        margin: 0;
        display: inline-block;
        vertical-align: text-top;
      }
    }

    .table-striped tbody tr .dragger-item {
      border: 0.75px solid rgba(0, 0, 0, 0.4);
    }

    .modal-dialog {
      max-width: 525px;
    }

    .text-center label {
      margin-bottom: 0;
    }
  }

  &__borderless-list .oi{
    align-self: center;
  }

  
  &__recent-actions {
    background-color: var(--table-odd-background);
    color: var(--default-font-color);
    .tabs {
      .nav-tabs {
        .nav-item {
          
          &.inactive, &:not(.active) {
            border-right: var(--list-border); 
          }
        }
      }
    }
  }
}

.sidebar__favorite-views__borderless-list {

  .dragger-item {
    cursor: move;
  
    label {
      cursor: move;
    }
  }

  .text-center label {
    margin-bottom: 0;
  }
}

.sidebar__bulk-views__borderless-list {

  thead {
    display: block;
  }

  tbody {
    display: block;
    max-height: $bulk-views-max-table-height;
    overflow-y: auto;
  }

  tbody tr td:first-child, thead th:first-child {
    width: 225px;
    max-width: 225px;
    overflow-wrap: break-word;
  }

  tbody tr td:nth-child(2), thead th:nth-child(2) {
    width: 112.5px;
  }
}

.modal-password-reset {
  .btn-cancellation {
    background-color: $grey-cloud;
    &:hover{
      background-color: $grey-cloud;
      cursor:not-allowed;
    }
  }
  .oi-x {
    &:hover{
      cursor:not-allowed;
    }
  }
}

.grid-icon {
  cursor: pointer;
  display: inline-block; 
  width: 20px; 
  line-height: 9px; 
  height: 20px; 
  margin-right: 2px;
  background-color: transparent;

  div {
    margin-right: 2px; 
    margin-bottom: 2px;
    background-color: var(--sidebar-font-color);
  }

  &.grid-active {
    div {
      background-color: $primary-color;
    }
  }
}

.grid-icon.grid-off {
  overflow: hidden;
  border: 2px solid var(--sidebar-font-color);
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 8px;

  div {
    border-bottom: 2px solid var(--sidebar-font-color);
    transform: rotate(-45deg);
    transform-origin: left;
    margin-right: 2px;
    margin-top: 13px;
    width: 23px;
  }

  &.grid-active {
    border-color: $primary-color;
    div {
      border-color: $primary-color;
    }
  }
}