::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

  
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  margin: 3.75px;
  -webkit-border-radius: 3.75px;
}

* {
  scrollbar-width: thin;
}

.theme-dark > * {
  scrollbar-color:  var(--grey-font-color) var(--table-odd-background);
}
.theme-light * {
  scrollbar-color: $grey-iron transparent;
}


.theme-dark *::-webkit-scrollbar-thumb {
  background-color: $font-grey-dark ;
}
.theme-dark *::-webkit-scrollbar-track {
  background-color: $table-background-odd-dark;
}

.theme-light *::-webkit-scrollbar-thumb {
  background-color: $grey-iron;
}
.theme-light *::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}