// FORM
.form-control {
    font-size: 0.75rem;
    &:focus {
        border-color: $primary-color;
    }
}

// POPOVER
.popover {
    padding: $padding-popover !important;
    border-radius: $border-radius;
    border-color: $grey-cloud;
    width: auto;
    overflow-x: auto;
    font-family: $font-name;

    .popover-title {
        font-size: $font-size-headline4 !important;
        font-weight: bold !important;
        color: $grey-charcoal;
    }

    .arrow {
        display: none;
    }

    &__market-filter {
        max-width: $popover_market_filter_width;
    }
}

.oi {
    color: var(--default-font-color);
    font-size: $font-size-headline3;
    &:hover,
    &:active {
        color: var(--default-font-color);
        filter: var(--hover-modifier);
    }

    &.white {
        color: $white;
        &:hover,
        &:active {
            color: $white;
        }
    }

    &.active {
        color: var(--active-color);
        &:hover,
        &:active {
            color: var(--active-color);
            filter: var(--hover-modifier);
        }
    }

    &.failed {
        color: $instrument-red;
        &:hover,
        &:active {
            color: lighten($instrument-red, 10%);
        }
    }

    &.success {
        color: $instrument-green;
        &:hover,
        &:active {
            color: lighten($instrument-green, 10%);
        }
    }

    &.open {
        color: $grey-iron;
        &:hover,
        &:active {
            color: lighten($grey-iron, 10%);
        }
    }

    &.pending {
        color: $instrument-orange;
        &:hover,
        &:active {
            color: lighten($instrument-orange, 10%);
        }
    }
}