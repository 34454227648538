@font-face {
  font-family: 'DINOT';
  src: url('../assets/fonts/DINOT.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-Bold';
  src: url('../assets/fonts/DINOT-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-BoldItalic';
  src: url('../assets/fonts/DINOT-BoldItalic.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-Italic';
  src: url('../assets/fonts/DINOT-Italic.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-Medium';
  src: url('../assets/fonts/DINOT-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-MediumItalic';
  src: url('../assets/fonts/DINOT-MediumItalic.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Icons';
  src: url('../assets/fonts/open-iconic.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../assets/fonts/Roboto-Italic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../assets/fonts/Roboto-BoldItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../assets/fonts/Roboto-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../assets/fonts/Roboto-BlackItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../assets/fonts/Roboto-Italic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../assets/fonts/Roboto-MediumItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../assets/fonts/Roboto-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../assets/fonts/Roboto-ThinItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../assets/fonts/Roboto-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../assets/fonts/Roboto-LightItalic.ttf');
  font-weight: normal;
  font-style: normal;
}
