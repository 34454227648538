.dock {
  &__wrapper {
    box-sizing: border-box;
    position: absolute;
    // transition: all 0.075s linear; Disabled animaction for better performance
    border-radius: $border-radius;
    user-select: none;
    z-index: 1;
    background: var(--dock-background);
    &.y-scroll {
      .react-resizable {
        // overflow-y: auto;
      }
    }

    .react-resizable {
      transition: all 0.1s linear;
      // transform: translateZ(0);
      overflow-y: auto;

      
    }

    .react-resizable-handle {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 101%;
      height: 101%;
      cursor: nwse-resize;
      z-index: -1;
    }

    &.cursor-both .react-resizable-handle {
      cursor: nwse-resize;
    }

    &.cursor-x .react-resizable-handle {
      cursor: ew-resize;
    }

    &.cursor-y .react-resizable-handle {
      cursor: ns-resize;
    }

    &.react-draggable-dragging {
      box-shadow: 0px 0px 22.5px 0px rgba(0, 173, 151, 1);
      z-index: 2;
      opacity: 0.75;

      .dock__shadow {
        background-color: $grey-opacity;
      }

      .cursor {
        background-color: $dragger-color;
      }
    }

    &.react-draggable-resizing {
      opacity: 0.75;

      .react-resizable {
        transition: width 0.1s linear;
      }

      .dock__shadow {
        z-index: 999;
      }
    }

    &.isColliding {
      &.allowed {
        box-shadow: 0px 0px 22.5px 0px rgba(0, 173, 151, 1);

        .dock__shadow {
          background-color: rgba(0, 173, 151, 1);
          opacity: 0.15;
        }
      }

      &.disallowed,
      &.removing {
        box-shadow: 0px 0px 22.5px 0px rgba(223, 0, 0, 1);

        .dock__shadow {
          background-color: rgba(223, 0, 0, 1);
          opacity: 0.15;
        }
      }
    }

    .cursor {
      display: block;
      position: absolute;
      width: 100%;
      height: $dragger-height;
      top: 0;
      left: 0;
      cursor: move;
      z-index: 22;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      &:hover {
        z-index: 26;
      }
    }

    .remove {
      position: absolute;
      left: 3.75px;
      bottom: 0px;
      z-index: 2;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    border-radius: $border-radius;
    border: var(--dock-border);
    background: var(--dock-background);
    .box {
      padding: $padding-default;
      padding-top: 7.5px - $dragger-height;
    }
    .cursor {
      background-color: var(--table-odd-background);
      &:hover {
        background-color: var(--hover-color);
      }
    }
  }

  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 0.75px dashed transparent;
    border-radius: $border-radius;
    background-color: $grey-opacity;
    transition: all 0.1s linear;
  }
}

.align-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}