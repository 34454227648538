.btn {
    text-align: center;
    padding: $padding-default;
    font-size: 0.75rem;
    border-radius: 0%;
    font-weight: 400;
    &.w50{
        width: 37.5px;
    }

    &.w100 {
        width: 75px;
    }

    &.w150 {
        width: 112.5px;
    }

    &.w200 {
        width: 150px;
    }

    &.w250 {
        width: 187.5px;
    }

    &.btn-primary {
        background-color: $primary-color;
        color: var(--primary-font-color);
        border: none;
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:hover,
        &:active{
            background-color: $primary-color;
            filter: var(--hover-modifier);
        }

        &:focus{
            background-color: $primary-color;
            box-shadow: 0 0 0 0.75px $secondary-color;
            filter: var(--hover-modifier);
        }

    }
    &:focus{
        box-shadow: 0 0 0 0.2rem var(--dock-background);
    }
    &.btn-secondary {
        background-color: transparent;
        color: $primary-color;
        border: 0.75px solid $primary-color;
        &:hover,
        &:active {
            background-color: $secondary-color;
            filter: var(--hover-modifier);
        }
        &:focus{
            background-color: $secondary-color;
            box-shadow: 0 0 0 0.75px $primary-color;
            filter: var(--hover-modifier);
        }
    }

    &.btn-approval {
        background-color: $primary-color;
        color: var(--default-font-color);
        border: 0.75px solid $primary-color;
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:hover,
        &:active{
            filter: var(--hover-modifier);
            background-color: $primary-color;
            color: $approval-font;
        }

        &:focus{
            filter: var(--hover-modifier);
        }

    }
    

    &.btn-cancellation {
        color: var(--default-font-color);
        border: 0.75px solid var(--default-font-color);
        margin-right: 5px;
        background-color: transparent;
        &:hover,
        &:active {
            filter: brightness(80%);
        }
        &:focus{
            filter: brightness(80%);
        }
    }

    &.btn-delete {
        color: $instrument-red;
        &:hover,
        &:active {
            color: lighten($instrument-red, 10%);
        }
        &:focus{
            color: $instrument-red;
            filter: var(--hover-modifier);
        }
    }

    &.disabled {
        color: var(--disabled-color) !important;
        cursor: not-allowed;
    }

}

.btn-group-toggle {
    label {
        &.btn {
            padding: 3.75px $padding-default;
            background-color: $grey-cloud;
            color: $white;
            &.ask.active {
                background-color: rgba(241,146,122, 1);
            }

            &.bid.active {
                background-color: rgba(170,249,132, 1);
            }
        }
    }
}