// COLORS
$white: rgba(255, 255, 255, 1); /// any uncolored backgrounds, text in colored elements
$black: rgba(0, 0, 0, 1);
$white-opacity: rgba(255, 255, 255, 0.2);
$black-opacity: rgba(0, 0, 0, 0.5);
$full-opacity:  rgba(13, 13, 13, 0);

$grey-light: rgba(232, 233, 237, 1);  // Background of app, odd rows in table
$grey-charcoal: rgb(53, 60, 73); /// Headlines, text
$grey-iron: rgb(88, 95, 109); /// non-branded side-menu, "toggled off" icons
$grey-opacity: rgba(88, 95, 109, 0.3); /// side-menu dragging, overlay background
$grey-cloud: rgba(191, 195, 204, 1); /// inactive/ greyed out elements, lines of boxes/search bar

$grey-dark: rgb(39, 38, 38);  // 262525 Background of app
$dock-background-dark: rgb(51, 51, 51); //333333
$table-background-odd-dark: rgb(60, 60, 60); //3c3c3c
$table-background-even-dark: rgb(79, 79, 79); //4f4f4f

$table-odd-color: rgb(243, 243, 243);
$table-even-color: rgba(255, 255, 255, 1);

$instrument-orange: rgba(255, 169, 100, 1); /// Instrument sheet 
$instrument-green: rgba(0, 173, 151, 1); /// Instrument sheet 
$instrument-green-medium: rgba(5, 109, 98, 1); /// Instrument sheet 
$instrument-green-dark: rgba(6, 60, 52, 1); /// Instrument sheet 
$instrument-red: rgb(248, 86, 86); /// Instrument sheet 
$instrument-red-medium: rgb(120, 0, 56); /// Instrument sheet 
$instrument-red-dark: rgba(73, 0, 26, 1); /// Instrument sheet 

$border-color-dark: rgb(118, 118, 118);
$border-color-light: rgb(118, 118, 118);

$alert-pink: rgba(248, 215, 218, 1);
$alert-red: rgba(114, 28, 36,1);

$bid-active: rgba(170,249,132, 1);
$ask-active: rgba(241,146,122, 1);

// FONTS
$font-size-headline1: 28.5px; /// Menu title in side menu
$font-size-headline2: 19.5px; /// All other heading in side menu
$font-size-headline3: 15px; /// Heading of tiles, tables, popups
$font-size-headline4: 13.5px; /// Heading of rows in tables, buttons
$font-size-normal: 13.5px;    /// Any text in general
$font-size-small: 12px;     /// Footnotes, copyright, recent action tab titles, column header
$font-size-verysmall: 10px;  //// additional info in tables
$font-grey-dark: rgb(118, 118, 118);
$font-grey-light: rgb(118, 118, 118);
$font-green-dark: rgb(131, 208, 104);
$font-green-light: rgb(131, 208, 104);
$font-red-dark: rgb(229, 77, 77);
$font-red-light: rgb(229, 77, 77);
$font-size-prices: 120%;
// LAYOUT
$padding-default: 7.5px;
$padding-non-aon-entry: 5%; // 22px; rather use relative values to handle resizing better

$padding-sidemenu: 15px;
$padding-sidemenu-topic: 15px 0 0 0;
$padding-sidemenu-title: 7.5px 0 0 0;
$padding-popover: 7.5px;


$padding-tile: 7.5px;
$padding-modal: 15px;


$title-without-padding-width: calc(100% - 15px); /// 100% width - 2 * $padding-tile:;

$margin-default: 7.5px;
$margin-modal: 11.25px;

$input-radio-width: 9px;
$input-radio-height: 9px;
$input-check-height: 9px;
$input-check-height: 9px;


$border-radius: 4px;

$recent-action-height: 187.5px;

// DRAGER
$dragger-height: 7.5px;
$dragger-color: $primary-color;

// TABLE
$actions-bottom-height: 37.5px;
$table-filter-block-width: 187.5px;

$profile-views-max-table-height: calc(100vh - 232.5px);
$bulk-views-max-table-height: calc(100vh - 375px);
$favorite-views-max-table-height: calc(100vh - 225px);

// MARKET
$market-row-height: 26.25px;
$market-row-height-compact: 45px;

$expiry-caret-width: 7.5px;
$expiry-caret-height: 7.5px;
$expiry-caret-offset: 3.75px;

$context-menu-width: 120.0stpx;

// CHART
$chart-options-min-width: 337.5px;

// Notifications
$bottom-position: 15px;
$right-position: 0;
$padding-notification: 15px 30px 15px 15px;
$padding-notification-button: 2px 5px 0px 5px;

// MODAL
$footer-height: 37.5px;

// LOGIN
$login-height: 262.5px;
$login-width: 262.5px;
$padding-login: 22.5px;

// RECENT ACTIONS
$tabs-link-padding:0 0 7.5px 3.75px;
$column-padding:2.25px 0;

// POPOVERS
$popover_market_filter_width: 637.5px;