.confirmation-block {
    p {
        color: var(--default-font-color);
        font-size: 0.75rem;
    }

    .d-flex {
        justify-content: center;
    }
}

.modal-profile .reserved-name {
    color: $instrument-red;
    padding: 4.5px 9px;
}

.profile {
    &__dropdown {
        background-color: var(--dock-background);
        position: relative;
        display: none;
        font-size: $font-size-normal;
        max-height: 500px;
        &.visible {
            display: block;
        }

        width: 100%;
        z-index: 1;
        
    }

    &__results {
        position: relative;
        background-color: var(--dock-background);
        overflow-y: auto;
        width: 93%;
        margin: auto;
        ul {
            padding: 0;
            margin: 5px 0px;
            list-style: none;
            color: var(--default-font-color);
            height: auto;
            max-height: 150px;
            font-size: $font-size-normal;

            li {
                cursor: pointer;
                border: var(--list-border);
                border-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                padding: $padding-default;
                margin: 2px 0px;

                label {
                    margin: 0;
                    cursor: pointer;
                }

                .dashboard-name {
                    padding-left: $padding-default;
                    padding-right: $padding-default;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }

                &:hover {
                    background-color: $grey-opacity;
                }
            }

            .blocked {
                cursor: not-allowed;
            }
        }
    }

}
