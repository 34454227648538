.favorite {
    &__new {
        margin-top: $padding-default;

        .form-group {
            margin-bottom: $margin-default;
            input {
                height: 40px;
                background: transparent;
            }
        }
    }
    
    &__results {
        position: relative;
        overflow-y: auto;
        width: 93%;
        margin: auto;
        ul {
            padding: 0;
            margin: 5px 0px;
            list-style: none;
            color: $grey-charcoal;
            height: auto;
            max-height: 150px;
            font-size: $font-size-normal;

            li {
                cursor: pointer;
                border: 0.75px solid $grey-cloud;
                border-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                padding: $padding-default;
                margin: 2px 0px;

                label {
                    margin: 0;
                    cursor: pointer;
                }

                .favorite-name {
                    padding-left: $padding-default;
                    padding-right: $padding-default;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }

                &:hover {
                    background-color: $grey-opacity;
                }
            }

            .blocked {
                cursor: not-allowed;
            }
        }
    }
    table {
        color: var(--default-font-color);
    }
    &__results {
        background-color: transparent;
        color: var(--default-font-color);
    }
    &__new {
        .form-group {
            input {
                color: var(--default-font-color);
            }
        }
    }
}

.modal-favorite-more{
    .modal-content{
        width: auto;
    }
}