.messages {
    position: relative;
    width: 100%;

    &__content {
        display: block;
        padding: 3.75px 18.75px 3.75px 7.5px;
        margin: 7.5px 0;
        border-radius: 5.25px;
        &.error {
            background: $alert-pink;
            color: $alert-red;

        }

        .close-btn {
            position: absolute;
            right: 7.5px;
            cursor: pointer;
            font-weight: bold;
            color: $black;
        }
    }
}