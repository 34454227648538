.analytics-panel {
  .docks__wrapper {
    .dock__wrapper.react-draggable-dragging {
      z-index: 25;
    }
  }
  &__wrapper {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    z-index: 5;
    transition: width 0.3s ease;
    box-sizing: border-box;
    height: 100%;
  }

  &.dashboard-quadrant-panel {
    position: relative;
    z-index: 4;
    top: 0px;
    height: 100%;

    &.hidden {
        visibility: hidden;
    }

    .analytics-quadrant {
        position: relative;
        width: auto;
        height: auto;
        float: left;
    }

    .resizer {
        position: absolute;
        border-color: $grey-iron;
        z-index: 10;
        &.resizer-x {
            cursor: move;
            top: 0;
            left: 0;
            width: 100%;
            height: 3px;
            border: 0px;
        }

        &.resizer-y {
            cursor: move;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            border: 0px;
        }
    }

    .analytics-panel__settings {
        position: absolute;
        bottom: 38px;
        right: -31px;
        left: auto;
    }

    .analytics-panel__content {
        height: 100%;
        background: transparent;
        border: 0px;
    }

    .icons-panel {
        bottom: 0px; 
        right: -33px;
    }

    .quadrants {
        display: block;
    }
  }
}

.dashboard-quadrant {
  position: relative;
  width: 50%;
  height: 50%;
  float: left;
  overflow: visible;
  padding-left: 2px;
  border-radius: 0;
  border: 0.75px solid;
  z-index: 0;
  border-color: var(--contrast-border-color);

  > span {
    font-size: 30px;
    color: var(--contrast-border-color);
  }

   &.isEmpty {
       text-align: center;
   }

   &.isColliding {
       &.allowed {
         box-shadow: 0px 0px 22.5px 0px rgba(0, 173, 151, 1);
 
         .dock__shadow {
           background-color: rgba(0, 173, 151, 1);
           opacity: 0.15;
         }
       }
 
       &.disallowed,
       &.removing {
         box-shadow: 0px 0px 22.5px 0px rgba(223, 0, 0, 1);
 
         .dock__shadow {
           background-color: rgba(223, 0, 0, 1);
           opacity: 0.15;
         }
       }
   }

   .box {
       max-height: 100%;
       display: block;
   }

   .orderbook {
       position: relative;
   }  

   .market__actions {
       position: fixed;

       ul {
           /* width: 0px; */
           float: right;
       }
   }
   .icon-popover {
       // max-height: unset;
       position: absolute;

       &.left-border {
         right: 0px;
       }
   }
}
