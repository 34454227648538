#context-menu {
  position: absolute;
  background: $white;
  border: 0.75px solid $grey-cloud;
  top: 0;
  left: 0;
  width: 75px;
  z-index: 4;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: $padding-default;
      cursor: pointer;
      &:not(:first-child) {
        border-bottom: 0.75px solid $grey-cloud;
      }

      &:hover {
        background-color: $grey-light;
      }
    }
  }
}
