.bulk__icons {
      font-size: $font-size-small;
      align-self: center;
}

.modal-bulk-order-upload {
      .modal-dialog {
            max-width: 375px !important;
            max-height: 187.5px;
            align-self: center;
      }
}

.modal-bulk {
      thead {
            z-index: 1000;
      }
      .oi {
            font-size: $font-size-headline3;
            align-self: center;
      }

      .modal-dialog {
            max-width: 70%;
           .modal-body {
                  height: 90%;

                  .modal-footer {
                        bottom: 0px;
                        right: 0px;
                        position: absolute;
                  }
            }

            .modal-content {
                  height: 90%;
                  position: absolute;
                  .bulktable-full,
                  .bulktable-half{
                        height: 43%;
                        .scrollable{
                              height: 100%;
                        }
                  }
                  .bulktable-errors{
                        max-height: 43%;
                        padding-bottom: 15px;
                  }
            }

            .dropzone {
                  margin-right: $margin-default;
                  width: 100%;
                  border: 0.75px solid;
                  text-align: center;
            }  
      }

      .form-control {
            background-color: var(--table-even-background);
            color: var(--default-font-color);
            
      }
      .number-spinner.input-group .form-control {
            width: 150px;
      }

      .form-group {
            label {
                  width: 170px;
            }
            .input-additional {
                  display: inline-block;
                  margin-left: 20px;
            }
      }
      #orderForm .modal-content .form-group.restriction {
            margin-left: 0px;
            padding: 0px;
      }
}

.modal-meet-bulk__popover {
      .modal-dialog {
            max-width: 550px;
            align-self: center;
      }

      .btn-group-toggle {
            label {
                  background-color: $grey-cloud;
                  color: $white;
                  
            &.ask.active {
                  background-color: $ask-active;
              }

              &.bid.active {
                  background-color: $bid-active;
              }
            }
      }

      .order-modify {
            position: absolute;
            bottom: 0;
            right: 0;
      }

}

.sidebar__bulk-views {
      height: 100%;
  }