.analytics-quadrants-panel {
    position: relative;
    height: inherit;
    z-index: 4;
    &.hidden {
        visibility: hidden;
    }

    .analytics-quadrant {
        position: relative;
        width: auto;
        height: auto;
        float: left;
    }
}